import {
  Container,
  Card,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  MenuItem,
  Button,
  IconButton,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserFormSkeleton from "../loadings/UserFormSkeleton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../api/FormatDate";
import React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { convertKeysToSnakeCase } from "../../utils/SnakeCaseConverter";
import { getCurrentUser } from "../../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";
import { AdminLogDataContext } from "../../contexts/AdminLogContext";
import { capitalizeAndReplaceUnderscore } from "../../utils/stringUtils";

export default function EditUserTask() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isChangePass, setIsChangePass] = React.useState<boolean>(false);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    isLoading: false,
  });
  const [formError, setFormError] = React.useState<any>({});
  const { globalData, setGlobalData, updateGlobalData } =
    React.useContext(GlobalDataContext);
  const { createAdminLogData } = React.useContext(AdminLogDataContext);

  const userToUpdate = async () => {
    setMainState({ ...mainState, isLoading: true });
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/accounts/tasks/${userId}`,
          headers: {
            Authorization: `Bearer ${globalData?.accessToken}`,
          },
        })
          .then((res: any) => {
            const resData = res.data.data;
            console.log(resData);
            setMainState({
              ...mainState,
              data: resData,
              isLoading: false,
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
            setMainState({ ...mainState, isLoading: false });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateUserTask = async () => {
    console.log(mainState.data);
    const loggedInUser = await getCurrentUser(globalData);
    if (loggedInUser?.status === 0) {
      updateGlobalData({
        role: "",
        username: null,
        idToken: null,
        isUserLogOut: true,
        lastLogin: null,
        lastLogout: null,
      });
      secureLocalStorage.removeItem("username");
      secureLocalStorage.removeItem("role");
      secureLocalStorage.removeItem("idToken");
      secureLocalStorage.removeItem("lastLogin");
      secureLocalStorage.removeItem("lastLogout");
      secureLocalStorage.removeItem("status");
      alert("Deactivated user cannot perform such action.");
      const cognitoLoginUrl =
        process.env.COGNITO_LOGIN_PAGE ||
        "https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions";
      window.location.href = cognitoLoginUrl;
      return;
    } else {
      setMainState({ ...mainState, isLoading: true });
      try {
        (async () => {
          axios({
            method: "patch",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/accounts/tasks/${userId}?available=${mainState.data?.available}&maxCapacity=${mainState.data?.maxCapacity}`,
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          })
            .then((res: any) => {
              createAdminLogData(globalData!.accessToken!, {
                event: "update_user_task",
                description: `Update task for user "${mainState.data?.username}"`,
                user: globalData.username,
                fullName: `${capitalizeAndReplaceUnderscore(
                  globalData.firstName
                )} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
              });
              navigate(-1);
              setMainState({ ...mainState, isLoading: false });
            })
            .catch((err: any) => {
              console.error(err?.response?.data);
              let errorMessage = "";
              if (err?.response?.status === 401)
                errorMessage = err?.response?.data;
              if (err?.response?.status === 400)
                errorMessage = err?.response?.data;
              setMainState({ ...mainState, isLoading: false });
            });
        })();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleAvailableChange = (event: SelectChangeEvent<string>) => {
    console.log(event.target.value);
    setMainState({
      ...mainState,
      data: { ...mainState.data, available: event.target.value },
    });
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const validateForm = () => {
    let isValid = true;
    const errors: any = {};

    // validate status
    if (
      mainState.data?.available === null ||
      mainState.data?.available === ""
    ) {
      errors.available = "Available should not be empty.";
      isValid = false;
    }

    setFormError(errors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      updateUserTask();
    }
  };

  React.useEffect(() => {
    userToUpdate();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 12,
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{
          mb: 2,
          padding: 0,
        }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 275,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#2683e0",
                  }}
                >
                  Edit User Task:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    color: "black",
                  }}
                >
                  {mainState.data?.username}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#607274",
                }}
              >
                Modify user account details
              </Typography>
            </Box>
          </Box>
          <hr />
          <br />
          <br />
          {mainState.isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="tp-mb-20px">
                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="tp-mb-20px"
                  >
                    <Typography variant="body1" className="tp-text-bold">
                      Available <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      id="available"
                      placeholder="Please select available here..."
                      onChange={handleAvailableChange}
                      size="small"
                      value={mainState?.data?.available || 0}
                    >
                      <MenuItem value={0}>Not Available</MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                    </Select>
                    {!!formError?.available && (
                      <FormHelperText error id="available">
                        {formError?.available}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Max Capacity <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="maxCapacity"
                      size="small"
                      type="number"
                      placeholder={`Enter max capacity Here...`}
                      value={mainState?.data?.maxCapacity}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "maxCapacity",
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
