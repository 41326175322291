const reserveCodes = [
  { value: 'AF', label: '201 AF - CTPL-BI ADJUSTER\'S FEE', premiumClass: '201' },
  { value: 'BI', label: '201 BI - CTPL-BI', premiumClass: '201' },
  { value: 'DC', label: '201 DC - CTPL-BI DEATH CLAIM', premiumClass: '201' },
  { value: 'IN', label: '201 IN - CTPL-INWARD CLAIM', premiumClass: '201' },
  { value: 'LF', label: '201 LF - CTPL-BI LAWYER\'S FEE', premiumClass: '201' },
  { value: 'OR', label: '201 OR - CTPL-BI-OTHER REIMBURSABLE EXP', premiumClass: '201' },
  { value: 'AF', label: '202 AF - OWN DAMAGE - ADJUSTER\'S FEE', premiumClass: '202' },
  { value: 'CA', label: '202 CA - OWN DAMAGE - CARNAP', premiumClass: '202' },
  { value: 'CS', label: '202 CS - OWN DAMAGE - CASH SETTLEMENT', premiumClass: '202' },
  { value: 'EF', label: '202 EF - OWN DAMAGE - ESTIMATOR\'S FEE', premiumClass: '202' },
  { value: 'IN', label: '202 IN - OWN DAMAGE-INWARD CLAIM', premiumClass: '202' },
  { value: 'LA', label: '202 LA - OWN DAMAGE - LABOR', premiumClass: '202' },
  { value: 'LF', label: '202 LF - OWN DAMAGE - LAWYER\'S FEE', premiumClass: '202' },
  { value: 'OR', label: '202 OR - OWN DAMAGE -OTHER REIMBURSABLE', premiumClass: '202' },
  { value: 'PA', label: '202 PA - OWN DAMAGE - PARTS', premiumClass: '202' },
  { value: 'RE', label: '202 RE - OWN DAMAGE - RECOVERY EXPENSE', premiumClass: '202' },
  { value: 'TF', label: '202 TF - OWN DAMAGE - TOWING FEE', premiumClass: '202' },
  { value: 'TL', label: '202 TL - OWN DAMAGE - TOTAL LOSS', premiumClass: '202' },
  { value: 'AF', label: '203 AF - THEFT - ADJUSTER\'S FEE', premiumClass: '203' },
  { value: 'CS', label: '203 CS - THEFT - CASH SETTLEMENT', premiumClass: '203' },
  { value: 'EF', label: '203 EF - THEFT - ESTIMATOR\'S FEE', premiumClass: '203' },
  { value: 'IN', label: '203 IN - THEFT-INWARD CLAIM', premiumClass: '203' },
  { value: 'LA', label: '203 LA - THEFT - LABOR', premiumClass: '203' },
  { value: 'LF', label: '203 LF - THEFT - LAWYER\'S FEE', premiumClass: '203' },
  { value: 'OR', label: '203 OR - THEFT -OTHER REIMBURSABLE EXP.', premiumClass: '203' },
  { value: 'PA', label: '203 PA - THEFT - PARTS', premiumClass: '203' },
  { value: 'TF', label: '203 TF - THEFT - TOWING FEE', premiumClass: '203' },
  { value: 'AF', label: '203 AF - THEFT - ADJUSTER\'S FEE', premiumClass: '2031' },
  { value: 'CS', label: '203 CS - THEFT - CASH SETTLEMENT', premiumClass: '2031' },
  { value: 'EF', label: '203 EF - THEFT - ESTIMATOR\'S FEE', premiumClass: '2031' },
  { value: 'IN', label: '203 IN - THEFT-INWARD CLAIM', premiumClass: '2031' },
  { value: 'LA', label: '203 LA - THEFT - LABOR', premiumClass: '2031' },
  { value: 'LF', label: '203 LF - THEFT - LAWYER\'S FEE', premiumClass: '2031' },
  { value: 'OR', label: '203 OR - THEFT -OTHER REIMBURSABLE EXP.', premiumClass: '2031' },
  { value: 'PA', label: '203 PA - THEFT - PARTS', premiumClass: '2031' },
  { value: 'TF', label: '203 TF - THEFT - TOWING FEE', premiumClass: '2031' },
  { value: 'AC', label: '204 AC - ACCESSORIES', premiumClass: '204' },
  { value: 'OR', label: '204 OR - ACCESSORIES-OTHER REIMBURSABLE', premiumClass: '204' },
  { value: 'AF', label: '205 AF - VTPL-BI ADJUSTER\'S FEE', premiumClass: '205' },
  { value: 'BI', label: '205 BI - VTPL-BI', premiumClass: '205' },
  { value: 'DC', label: '205 DC - VTPL-BI DEATH CLAIM', premiumClass: '205' },
  { value: 'IN', label: '205 IN - VTPL-BI INWARD CLAIM', premiumClass: '205' },
  { value: 'LF', label: '205 LF - VTPL-BI LAWYER\'S FEE', premiumClass: '205' },
  { value: 'OR', label: '205 OR - VTPL-BI -OTHER REIMBURSABLE EX', premiumClass: '205' },
  { value: 'AF', label: '206 AF - VTPL-PD ADJUSTER\'S FEE', premiumClass: '206' },
  { value: 'CS', label: '206 CS - VTPL-PD CASH SETTLEMENT', premiumClass: '206' },
  { value: 'EF', label: '206 EF - VTPL-PD ESTIMATOR\'S FEE', premiumClass: '206' },
  { value: 'IN', label: '206 IN - VTPL-INWARD', premiumClass: '206' },
  { value: 'LA', label: '206 LA - VTPL-PD LABOR', premiumClass: '206' },
  { value: 'LF', label: '206 LF - VTPL-PD LAWYER\'S FEE', premiumClass: '206' },
  { value: 'OR', label: '206 OR - VTPL-PD OTHER REIMBURSABLE EXP', premiumClass: '206' },
  { value: 'PA', label: '206 PA - VTPL-PD PARTS', premiumClass: '206' },
  { value: 'PD', label: '206 PD - VTPL PROPERTY DAMAGE', premiumClass: '206' },
  { value: 'TF', label: '206 TF - VTPL-PD TOWING FEE', premiumClass: '206' },
  { value: 'AF', label: '206 AF - VTPL-PD ADJUSTER\'S FEE', premiumClass: '2061' },
  { value: 'CS', label: '206 CS - VTPL-PD CASH SETTLEMENT', premiumClass: '2061' },
  { value: 'EF', label: '206 EF - VTPL-PD ESTIMATOR\'S FEE', premiumClass: '2061' },
  { value: 'IN', label: '206 IN - VTPL-INWARD', premiumClass: '2061' },
  { value: 'LA', label: '206 LA - VTPL-PD LABOR', premiumClass: '2061' },
  { value: 'LF', label: '206 LF - VTPL-PD LAWYER\'S FEE', premiumClass: '2061' },
  { value: 'OR', label: '206 OR - VTPL-PD OTHER REIMBURSABLE EXP', premiumClass: '2061' },
  { value: 'PA', label: '206 PA - VTPL-PD PARTS', premiumClass: '2061' },
  { value: 'PD', label: '206 PD - VTPL PROPERTY DAMAGE', premiumClass: '2061' },
  { value: 'TF', label: '206 TF - VTPL-PD TOWING FEE', premiumClass: '2061' },
  { value: 'AF', label: '207 AF - UPPA ADJUSTER\'S FEE', premiumClass: '207' },
  { value: 'BI', label: '207 BI - UPPA BODILY INJURY', premiumClass: '207' },
  { value: 'DC', label: '207 DC - UPPA DEATH', premiumClass: '207' },
  { value: 'IN', label: '207 IN - UPPA-INWARD', premiumClass: '207' },
  { value: 'LF', label: '207 LF - UPPA LAWYER\'S FEE', premiumClass: '207' },
  { value: 'OR', label: '207 OR - UPPA -OTHER REIMBURSABLE EXP.', premiumClass: '207' },
  { value: 'AF', label: '208 AF - FLOOD AND TYPHOON-ADJUSTERSFEE', premiumClass: '208' },
  { value: 'CS', label: '208 CS - FLOOD AND TYPHOON-CASH SETTLE', premiumClass: '208' },
  { value: 'EF', label: '208 EF - FLOOD AND TYPHOON-EST FEE', premiumClass: '208' },
  { value: 'FT', label: '208 FT - FLOOD AND TYPHOON', premiumClass: '208' },
  { value: 'IN', label: '208 IN - FLOOD AND TYPHOON-INWARD', premiumClass: '208' },
  { value: 'LA', label: '208 LA - FLOOD AND TYPHOON-LABOR', premiumClass: '208' },
  { value: 'LF', label: '208 LF - FLOOD AND TYPHOON-LAWYERS FEE', premiumClass: '208' },
  { value: 'OR', label: '208 OR - FLOOD & TYPHOON-OTHER REIMBURS', premiumClass: '208' },
  { value: 'PA', label: '208 PA - FLOOD AND TYPHOON- PARTS', premiumClass: '208' },
  { value: 'TF', label: '208 TF - FLOOD AND TYPHOON-TOWING FEE', premiumClass: '208' },
  { value: 'TL', label: '208 TL - FLOOD AND TYPHOON-TOTAL LOSS', premiumClass: '208' },
  { value: 'AF', label: '209 AF - ACTS OF GOD -ADJUSTERS FEE', premiumClass: '209' },
  { value: 'AG', label: '209 AG - ACTS OF GOD', premiumClass: '209' },
  { value: 'CS', label: '209 CS - ACTS OF GOD-CASH SETTLEMENT', premiumClass: '209' },
  { value: 'EF', label: '209 EF - ACTS OF GOD-ESTIMATORS FEE', premiumClass: '209' },
  { value: 'IN', label: '209 IN - ACTS OF GOD-INWARD', premiumClass: '209' },
  { value: 'LA', label: '209 LA - ACTS OF GOD-LABOR', premiumClass: '209' },
  { value: 'LF', label: '209 LF - ACTS OF GOD-LAWYERS FEE', premiumClass: '209' },
  { value: 'OR', label: '209 OR - ACTS OF GOD-OTHER REIMBURSABLE', premiumClass: '209' },
  { value: 'PA', label: '209 PA - ACTS OF GOD-PARTS', premiumClass: '209' },
  { value: 'TF', label: '209 TF - ACTS OF GOD-TOWING FEE', premiumClass: '209' },
  { value: 'TL', label: '209 TL - ACTS OF GOD-TOTAL LOSS', premiumClass: '209' },
  { value: 'NO', label: '210 NO - NEW FOR OLD', premiumClass: '210' },
  { value: 'OR', label: '210 OR - NEW FOR OLD-OTHER REIMBURSABLE', premiumClass: '210' },
  { value: 'OR', label: '211 OR - SRCC -OTHER REIMBURSABLE EXP.', premiumClass: '211' },
  { value: 'SR', label: '211 SR - SRCC', premiumClass: '211' },
  { value: 'OR', label: '212 OR - 24-HR ROAD. ASST-OTHER REIMBUR', premiumClass: '212' },
  { value: 'RA', label: '212 RA - 24-HOUR ROADSIDE ASSISTANCE', premiumClass: '212' },
  { value: 'EP', label: '213 EP - EXCESS PASSENGER LIABILITY', premiumClass: '213' },
  { value: 'OR', label: '213 OR - EXCESS PASS-OTHER REIMBURSABLE', premiumClass: '213' },
  { value: 'AT', label: '214 AT - ALTERNATIVE TRANSPO ALLOWANCE', premiumClass: '214' },
  { value: 'OR', label: '214 OR - ALTER. TRANSPO-OTHER REIMBURSA', premiumClass: '214' },
  { value: 'AF', label: '216 AF - CSL VTPL-BI/PD ADJUSTERS FEE', premiumClass: '216' },
  { value: 'BI', label: '216 BI - CSL VTPL-BI', premiumClass: '216' },
  { value: 'CS', label: '216 CS - CSL VTPL-PD CASH SETTLEMENT', premiumClass: '216' },
  { value: 'DC', label: '216 DC - CSL VTPL-BI DEATH CLAIM', premiumClass: '216' },
  { value: 'EF', label: '216 EF - CSL VTPL-PD ESTIMATORS FEE', premiumClass: '216' },
  { value: 'IN', label: '216 IN - CSL VTPL-BI/PD INWARD', premiumClass: '216' },
  { value: 'LA', label: '216 LA - CSL VTPL-PD LABOR', premiumClass: '216' },
  { value: 'LF', label: '216 LF - CSL VTPL-BI/PD LAWYERS FEE', premiumClass: '216' },
  { value: 'PA', label: '216 PA - CSL VTPL-PD PARTS', premiumClass: '216' },
  { value: 'PD', label: '216 PD - CSL VTPL-PROPERTY DAMAGE', premiumClass: '216' },
  { value: 'TF', label: '216 TF - CSL VTPL-PD TOWING FEE', premiumClass: '216' },
  { value: 'TL', label: '216 TL - CSL VTPL-PD TOTAL LOSS', premiumClass: '216' }
];

export default reserveCodes;
