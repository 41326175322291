import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import * as React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import reserveCodes from "../reserveCode";
import { ReserveCodeKey, reserveCodeMapping } from "../reserveCodeMapping";
// import { convertToMilitaryTime } from '../../utils/stringUtils';
import { format } from 'date-fns';


interface ClaimRegistrationProps {
  transId: any,
  claimRegistrationData: any,
  setClaimRegistrationData: any,
  tag: string,
}

interface IClaimEntry {
  value: string;
  label: string;
  premiumClass: string;
}

const ClaimRegistrationDetail = ({
  transId,
  tag,
  claimRegistrationData,
  setClaimRegistrationData,
  // businessRules
}: ClaimRegistrationProps) => {
  const [premiumClass, setPremiumClass] = React.useState("202");
  const [reserveCode, setReserveCode] = React.useState("");
  const [estimationAmount, setEstimationAmount] = React.useState("");
  const [newPremiumClass, setNewPremiumClass] = React.useState("202");
  const [newReserveCode, setNewReserveCode] = React.useState("");
  const [newEstimationAmount, setNewEstimationAmount] = React.useState("");
  const [entries, setEntries] = React.useState<
    { premiumClass: string; reserveCode: string; estimationAmount: string; codeDesc: string; tag: string; }[]
  >([]);
  const [claimTag, setClaimTag] = React.useState("");
  const [newTagClaim, setNewTagClaim] = React.useState("");
  const { globalData } = React.useContext(GlobalDataContext);
  const [editIndex, setEditIndex] = React.useState<number>(0);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [claimLoading, setClaimLoading] = React.useState<boolean>(false);

  const premiumClasses = [

    // { value: 201, label: "201 - COMPULSORY TPL - BODILY INJURY" },
    { value: "202", label: "202 - OWN DAMAGE" },
    // { value: 203, label: "203 - THEFT - Carnap" },
    { value: "203", label: "203 - THEFT - Partial Theft" },
    // { value: 205, label: "205 - VTPL - BODILY INJURY" },
    { value: "206", label: "206 - VTPL - PROPERTY DAMAGE" },
    // { value: 2061, label: "206 - VTPL - PROPERTY DAMAGE Other than Vehicle" },
    // { value: 207, label: "207 - AUTO PERSONAL ACCIDENT" },
    { value: "209", label: "209 - ACTS OF NATURE" },
    // { value: 211, label: "211 - STRIKES, RIOTS, CIVIL COMMOTIONS" },
    // { value: 212, label: "212 - 24-HOUR ROADSIDE ASSISTANCE" },
    // { value: 214, label: "214 - ALTERNATIVE TRANSPO ALLOWANCE" },
    // { value: 216, label: "216 - CSL VTPL-BI/PD" },
  ];

  const handlePremiumClassChange = (event: any) => {
    // console.log(typeof event.target.value)
    setPremiumClass(event.target.value);
    setReserveCode(""); // Reset reserve code when premium class changes
  };

  // alert(tag)

  const handleReserveCodeChange = (event: any) => {
    setReserveCode(event.target.value);
  };

  const handleEstimationAmountChange = (event: any) => {
    setEstimationAmount(event.target.value);
  };

  // region for existing entries

  const claimsModificationCreate = async (
    updatedEntries: {
      premiumClass: string;
      reserveCode: string;
      estimationAmount: string;
      codeDesc: string;
      tag: string;
    }[]
  ) => {
    setClaimLoading(true);
    // Replace these with actual values from your context or fetched data
    const riskNumber = claimRegistrationData?.riskNumber; // Example value
    const policyNo = claimRegistrationData?.policyNumber; // Example value
    const claimStatus = claimRegistrationData?.claimStatus; // Example value
    const dateOccurs = claimRegistrationData?.dateLoss; // Example value
    const claimDescription = claimRegistrationData?.claimDesc; // Example value
    const dateReported = claimRegistrationData?.dateReported;
    const majorEventCode = claimRegistrationData?.claimMajoreventcode;
    const claimNumber = claimRegistrationData?.claimNumber;
    // Update claimFilingapprovaltime to current datetime
    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const getKeyByValue = (value: string): ReserveCodeKey | undefined => {
      return (Object.keys(reserveCodeMapping) as ReserveCodeKey[]).find(
        (key) => reserveCodeMapping[key] === value
      );
    };
    const formattedDateTime = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const claimsPayload = {
      claimRiskNumber: riskNumber,
      claimNumber: claimNumber,
      policyNumber: policyNo,
      claimStatus: claimStatus,
      dateOccurs: format(new Date(dateOccurs), "yyyy-MM-dd"),
      claimDescription: claimDescription,
      dateReported: dateReported,
      subrogation: "N",
      claimMajoreventCode: majorEventCode,
      balanceOutstanding01: updatedEntries[0]?.estimationAmount || "",
      balanceOutstanding02: updatedEntries[1]?.estimationAmount || "",
      balanceOutstanding03: updatedEntries[2]?.estimationAmount || "",
      balanceOutstanding04: updatedEntries[3]?.estimationAmount || "",
      balanceOutstanding05: updatedEntries[4]?.estimationAmount || "",
      balanceOutstanding06: updatedEntries[5]?.estimationAmount || "",
      balanceOutstanding07: updatedEntries[6]?.estimationAmount || "",
      balanceOutstanding08: updatedEntries[7]?.estimationAmount || "",
      balanceOutstanding09: updatedEntries[8]?.estimationAmount || "",
      balanceOutstanding10: updatedEntries[9]?.estimationAmount || "",
      premiumClass01: updatedEntries[0]?.premiumClass.slice(0, 3) || "",
      premiumClass02: updatedEntries[1]?.premiumClass.slice(0, 3) || "",
      premiumClass03: updatedEntries[2]?.premiumClass.slice(0, 3) || "",
      premiumClass04: updatedEntries[3]?.premiumClass.slice(0, 3) || "",
      premiumClass05: updatedEntries[4]?.premiumClass.slice(0, 3) || "",
      premiumClass06: updatedEntries[5]?.premiumClass.slice(0, 3) || "",
      premiumClass07: updatedEntries[6]?.premiumClass.slice(0, 3) || "",
      premiumClass08: updatedEntries[7]?.premiumClass.slice(0, 3) || "",
      premiumClass09: updatedEntries[8]?.premiumClass.slice(0, 3) || "",
      premiumClass10: updatedEntries[9]?.premiumClass.slice(0, 3) || "",
      reserveCode01: updatedEntries[0]?.reserveCode || "",
      reserveCode02: updatedEntries[1]?.reserveCode || "",
      reserveCode03: updatedEntries[2]?.reserveCode || "",
      reserveCode04: updatedEntries[3]?.reserveCode || "",
      reserveCode05: updatedEntries[4]?.reserveCode || "",
      reserveCode06: updatedEntries[5]?.reserveCode || "",
      reserveCode07: updatedEntries[6]?.reserveCode || "",
      reserveCode08: updatedEntries[7]?.reserveCode || "",
      reserveCode09: updatedEntries[8]?.reserveCode || "",
      reserveCode10: updatedEntries[9]?.reserveCode || "",
    };

    const claimsPayloadDb = {
      ...claimRegistrationData,
      balanceOutstanding01: updatedEntries[0]?.estimationAmount || "",
      balanceOutstanding02: updatedEntries[1]?.estimationAmount || "",
      balanceOutstanding03: updatedEntries[2]?.estimationAmount || "",
      balanceOutstanding04: updatedEntries[3]?.estimationAmount || "",
      balanceOutstanding05: updatedEntries[4]?.estimationAmount || "",
      balanceOutstanding06: updatedEntries[5]?.estimationAmount || "",
      balanceOutstanding07: updatedEntries[6]?.estimationAmount || "",
      balanceOutstanding08: updatedEntries[7]?.estimationAmount || "",
      balanceOutstanding09: updatedEntries[8]?.estimationAmount || "",
      balanceOutstanding10: updatedEntries[9]?.estimationAmount || "",
      premiumClass01: updatedEntries[0]?.premiumClass.slice(0, 3) || "",
      premiumClass02: updatedEntries[1]?.premiumClass.slice(0, 3) || "",
      premiumClass03: updatedEntries[2]?.premiumClass.slice(0, 3) || "",
      premiumClass04: updatedEntries[3]?.premiumClass.slice(0, 3) || "",
      premiumClass05: updatedEntries[4]?.premiumClass.slice(0, 3) || "",
      premiumClass06: updatedEntries[5]?.premiumClass.slice(0, 3) || "",
      premiumClass07: updatedEntries[6]?.premiumClass.slice(0, 3) || "",
      premiumClass08: updatedEntries[7]?.premiumClass.slice(0, 3) || "",
      premiumClass09: updatedEntries[8]?.premiumClass.slice(0, 3) || "",
      premiumClass10: updatedEntries[9]?.premiumClass.slice(0, 3) || "",
      reserveCode01: updatedEntries[0]?.reserveCode || "",
      reserveCode02: updatedEntries[1]?.reserveCode || "",
      reserveCode03: updatedEntries[2]?.reserveCode || "",
      reserveCode04: updatedEntries[3]?.reserveCode || "",
      reserveCode05: updatedEntries[4]?.reserveCode || "",
      reserveCode06: updatedEntries[5]?.reserveCode || "",
      reserveCode07: updatedEntries[6]?.reserveCode || "",
      reserveCode08: updatedEntries[7]?.reserveCode || "",
      reserveCode09: updatedEntries[8]?.reserveCode || "",
      reserveCode10: updatedEntries[9]?.reserveCode || "",
      codeDesc01: updatedEntries[0]?.codeDesc || "",
      codeDesc02: updatedEntries[1]?.codeDesc || "",
      codeDesc03: updatedEntries[2]?.codeDesc || "",
      codeDesc04: updatedEntries[3]?.codeDesc || "",
      codeDesc05: updatedEntries[4]?.codeDesc || "",
      codeDesc06: updatedEntries[5]?.codeDesc || "",
      codeDesc07: updatedEntries[6]?.codeDesc || "",
      codeDesc08: updatedEntries[7]?.codeDesc || "",
      codeDesc09: updatedEntries[8]?.codeDesc || "",
      codeDesc10: updatedEntries[9]?.codeDesc || "",
      tag01: updatedEntries[0]?.tag || "",
      tag02: updatedEntries[1]?.tag || "",
      tag03: updatedEntries[2]?.tag || "",
      tag04: updatedEntries[3]?.tag || "",
      tag05: updatedEntries[4]?.tag || "",
      tag06: updatedEntries[5]?.tag || "",
      tag07: updatedEntries[6]?.tag || "",
      tag08: updatedEntries[7]?.tag || "",
      tag09: updatedEntries[8]?.tag || "",
      tag10: updatedEntries[9]?.tag || "",
    };

    // Assuming the backend expects claimFilingapprovaltime
    // If claimFilingapprovaltime is part of the payload, include it
    // If not, ensure your backend sets it automatically
    (claimsPayload as any)["claimFilingapprovaltime"] = formattedDateTime;
    // // (claimsPayloadDb as any)["claimFilingapprovaltime"] = currentDatetime;
    // // (claimsPayloadDb as any)["saveDate"] = currentDatetime;

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration`,
        claimsPayloadDb, // Adjust based on what your backend expects

        {
          params: { transId: transId },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // ... [rest of your try block]
      alert("Claim DB updated successfully!");
    } catch (err: any) {
      // Handle errors
      alert(err.message || "An error occurred while updating the claim.");
    }

    try {
      // Fetch signature
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              claims: claimsPayload,
            },
            signature: "",
          }),
        }
      );

      if (!signatureResponse.ok) {
        // throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      // Register claim
      const claimRegister = await fetch(
        `${process.env.REACT_APP_CLAIM_MODIFICATION_CREATE_ENDPOINT}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              claims: claimsPayload,
            },
            signature: signature,
          }),
        }
      );

      if (!claimRegister.ok) {
        // throw new Error(`Error Claim Register: ${claimRegister.statusText}`);
        console.error("Error");
      }

      // Handle success (e.g., notify user, refresh data, etc.)
      await fetchClaimRegistrationData();
      // alert("Claim API updated successfully!");
      setClaimLoading(false);
    } catch (err: any) {
      // Handle errors
      alert(err.message || "An error occurred while updating the claim.");
      setClaimLoading(false);
    }
  };

  const toggleEditDialog = (open: boolean, index: number) => {
    setOpenDialog(open);
    setEditIndex(index);
    if (open === true) {
      setNewPremiumClass(entries[index]?.premiumClass);
      setNewReserveCode(entries[index]?.reserveCode);
      setNewEstimationAmount(entries[index]?.estimationAmount);
      setNewTagClaim(entries[index]?.tag)
    } else {
      setNewPremiumClass("");
      setNewReserveCode("");
      setNewEstimationAmount("");
      setNewTagClaim("insured");
    }
  };

  const handleNewPremiumClassChange = (event: any, index: number) => {
    setNewPremiumClass(event.target.value)
  };

  const handleNewReserveCodeChange = (event: any, index: number) => {
    setNewReserveCode(event.target.value)
  };

  const handleNewEstimationAmountChange = (event: any, index: number) => {
    setNewEstimationAmount(parseFloat(event.target.value).toLocaleString(
      "en-PH",
      { style: "currency", currency: "PHP" }
    ))
  };

  const handleSaveEditedEntry = (index: number) => {
    setEntries((prevEntries) => {
      // Create a new array copy
      const updatedEntries = [...prevEntries];

      // Update the specific attribute in the object at the given index
      updatedEntries[index] = {
        premiumClass: newPremiumClass,
        reserveCode: newReserveCode,
        tag: newTagClaim,
        estimationAmount: newEstimationAmount,
        codeDesc: newReserveCode ? (reserveCodes.find(
          (rc) => rc.value === newReserveCode
        )?.label || "") : ""
      };



      claimsModificationCreate(updatedEntries);
      // Return the modified array to update the state
      return updatedEntries;
    });

    toggleEditDialog(false, 0);

  }

  const handleAddNewEntry = () => {
    if (entries.length < 10) {
      setEntries((prevEntries) => {
        // Create a new array copy
        const updatedEntries = [...prevEntries, {
          premiumClass: premiumClass,
          reserveCode: reserveCode,
          tag: claimTag,
          estimationAmount: parseFloat(estimationAmount).toLocaleString(
            "en-PH",
            { style: "currency", currency: "PHP" }
          ),
          codeDesc: reserveCode ? (reserveCodes.find(
            (rc) => rc.value === reserveCode && rc.premiumClass === premiumClass
          )?.label || "") : ""
        }];



        claimsModificationCreate(updatedEntries);
        // Return the modified array to update the state
        return updatedEntries;
      });
      setPremiumClass("");
      setReserveCode("");
      setEstimationAmount("");
    }
  };

  const handleDeleteNewEntry = (index: any) => {
    setEntries((prevEntries) => {
      // Create a new array copy
      const updatedEntries = prevEntries.filter((entry, i) => i !== index);

      claimsModificationCreate(updatedEntries);
      // Return the modified array to update the state
      return updatedEntries;
    });
    // setEntries(entries.filter((entry, i) => i !== index));
  };

  const filteredExistingReserveCode = (
    premiumClassCode: string
  ): IClaimEntry[] => {
    // console.log(premiumClassCode)
    return reserveCodes.filter(
      (code) => code.premiumClass === premiumClassCode
    );
  };

  const isDisabled = entries.length >= 10;

  // Fetch document validation data on component mount
  const fetchClaimRegistrationData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setClaimRegistrationData(data.data);

    } catch (error) {
      console.error("Error fetching claim registration data:", error);
    }
  };

  React.useEffect(() => {
    fetchClaimRegistrationData();
  }, [transId]); // Run only when transId changes

  // Map claimRegistrationData to entries for table display
  React.useEffect(() => {
    if (claimRegistrationData) {
      const newEntries = [];
      for (let i = 1; i <= 10; i++) {
        const index = String(i).padStart(2, '0'); // Pads single-digit numbers with a leading zero
        const premiumClassKey = `premiumClass${index}`;
        const reserveCodeKey = `reserveCode${index}`;
        const balanceOutstandingKey = `balanceOutstanding${index}`;
        const codeDescriptionKey = `codeDesc${index}`;
        const tag = claimRegistrationData[`tag${index}`];


        const premiumClass = claimRegistrationData[premiumClassKey];
        const reserveCode = claimRegistrationData[reserveCodeKey];
        const balanceOutstanding = claimRegistrationData[balanceOutstandingKey];
        const codeDescription = claimRegistrationData[codeDescriptionKey];

        if (premiumClass && reserveCode && balanceOutstanding && codeDescription) {
          newEntries.push({
            premiumClass,
            reserveCode,
            tag,
            estimationAmount: balanceOutstanding,
            codeDesc: codeDescription,
          });
        }
      }

   
      setEntries(newEntries);
    }
  }, [claimRegistrationData]);

  return (
    <>
      <Typography variant="body2" color="error" marginBottom="20px" textAlign="center">
        Maximum Entry: {10 - entries.length}
      </Typography>
      <Typography variant="body1" color="primary" className="tp-text-bold">
        Loss Reserve:
      </Typography>
      <Grid container spacing={2} marginTop="5px">
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Premium Class"
            value={premiumClass}
            size="small"
            onChange={handlePremiumClassChange}
            fullWidth
            disabled={isDisabled}
          >
            {premiumClasses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            select
            label="Reserve Code"
            value={reserveCode}
            onChange={handleReserveCodeChange}
            fullWidth
            size="small"
            disabled={!premiumClass || isDisabled}
          >
            {filteredExistingReserveCode(premiumClass).map(
              (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <TextField
            label="Estimation Amount"
            type="number"
            fullWidth
            size="small"
            value={estimationAmount}
            onChange={handleEstimationAmountChange}
            disabled={!premiumClass || isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Tag</InputLabel>
            <Select
              label="Tag"
              value={claimTag}
              onChange={(e) => setClaimTag(e.target.value)}
              defaultValue="insured"
            >
              {[
                "insured",
                "tp1",
                "tp2",
                "tp3",
                "tp4",
                "tp5",
                "p1",
                "p2",
                "p3",
                "p4",
                "p5",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1} lg={1} textAlign="center">
          <Button
            color="error"
            onClick={handleAddNewEntry}
            disabled={
              !premiumClass ||
              !reserveCode ||
              !estimationAmount ||
              isDisabled
            }
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Premium Class</TableCell>
              <TableCell>Reserve Code</TableCell>
              <TableCell>Estimation Amount</TableCell>
              <TableCell>Tag</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.filter(entry => entry.tag === tag).map((entry, index) => (
              <TableRow key={index}>
                <TableCell>
                  {
                    premiumClasses.find(
                      (pc) => pc.value === entry.premiumClass
                    )?.label
                  }
                </TableCell>
                <TableCell>{entry.codeDesc}</TableCell>
                <TableCell>{entry.estimationAmount}</TableCell>
                <TableCell>{entry.tag}</TableCell>
                <TableCell>
                  <TableCell>
                    <>
                      <IconButton color="primary" onClick={() => toggleEditDialog(true, index)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDeleteNewEntry(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  </TableCell>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={(event, reason) => toggleEditDialog(false, 0)}
      >
        <DialogTitle>Edit Entry #{editIndex}</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            fullWidth
            select
            label="Premium Class"
            value={
              premiumClasses.find((pc) => pc.value === newPremiumClass)?.value
            }
            onChange={(event) => handleNewPremiumClassChange(event, editIndex)}
          >
            {premiumClasses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            select
            fullWidth
            label="Reserve Code"
            value={
              reserveCodes.find((rc) => rc.value === newReserveCode)?.value
            }
            onChange={(event) => handleNewReserveCodeChange(event, editIndex)}
            disabled={!newPremiumClass}
          >
            {filteredExistingReserveCode(newPremiumClass).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <TextField
            fullWidth
            label="Estimation Amount"
            type="number"
            value={parseFloat(newEstimationAmount.replace(/[₱,]/g, ""))}
            onChange={(event) =>
              handleNewEstimationAmountChange(event, editIndex)
            }
          />
          <br />
          <br />
          <FormControl fullWidth>
            <InputLabel>Tag</InputLabel>
            <Select
              label="Tag"
              value={newTagClaim}
              onChange={(e) => setNewTagClaim(e.target.value)}
              defaultValue="insured"
            >
              {[
                "insured",
                "tp1",
                "tp2",
                "tp3",
                "tp4",
                "tp5",
                "p1",
                "p2",
                "p3",
                "p4",
                "p5",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveEditedEntry(editIndex)}
          >
            Save
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => toggleEditDialog(false, 0)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={claimLoading === true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ClaimRegistrationDetail;
