// ListOfParts.tsx
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import { IShopEstimate } from '../../types/ShopEstimate';
import {
  deletePartsById,
  fetchBrands,
  fetchModels,
  fetchParts,
  getPartsByDeId,
  postParts,
  updatePart,
} from './ApiService'; // Import the extended API functions
import Depreciation from './Depreciation';

type VehiclePart = {
  brand: string;
  model: string;
  parts: string;
  price: number;
};

type CartItem = {
  id?: number;
  deId?: number;
  transId?: number;
  name: string;
  amount: number;
  qty: number;
  condition: 'Original' | 'Replacement Parts' | 'Surplus' | 'Taiwan Parts' | 'Not Standard Part' | 'No Price Established' | 'Estimated Price' | 'Subject to Availability' | 'As per sample' | 'No stock' | 'Standard operating procedure when replacing parts' | '';
  globalDepreciation: boolean;
  shopPrice: number;
  totalAmount: number;
  depreciation: number;
  discount: number;
  netAmount: number;
  shopAmount: boolean;
  remarks: string;
  depreciationFixed?: boolean;
  saveDate?: string;
  isEditing?: boolean; // To track if the row is in edit mode
};

type ListOfPartsProps = {
  onTotalAmountChange: (total: string) => void;
  onTotalDepreciationChange: (totalDepreciation: number) => void;
  onTotalOfferAmountChange: (totalOfferAmount: number) => void;
  updateCart: (cart: CartItem[]) => void;
  onTotalDiscountChange?: (totalDiscount: number) => void;
  onVatChange?: (vat: number) => void;
  initialBrand: string | null;
  initialModel: string | null;
  policyNo: string;
  crDate: string;
  perilType: string | null;
};

const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};



const ListOfParts: React.FC<ListOfPartsProps> = ({
  onTotalAmountChange,
  onTotalDepreciationChange,
  onTotalOfferAmountChange,
  updateCart,
  onTotalDiscountChange, // Destructure the new prop
  onVatChange,
  policyNo,
  crDate,
  perilType,
  initialBrand,
  initialModel,
}) => {
  const [brand, setBrand] = useState<string | null>(initialBrand);
  const [model, setModel] = useState<string | null>(initialModel);
  const [modelInputValue, setModelInputValue] = useState<string>(''); // New state for inputValue
  const [part, setPart] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const { transId } = useParams<{ transId: string }>();
  const { deId } = useParams<{ deId: string }>();
  const [qty, setQty] = useState<string>('1');
  const [cart, setCart] = useState<CartItem[]>([]);
  // const [filteredModels, setFilteredModels] = useState<string[]>([]);
  // const [filteredParts, setFilteredParts] = useState<VehiclePart[]>([]);
  const [brandOptions, setBrandOptions] = useState<string[]>([]);
  const [brandLoading, setBrandLoading] = useState<boolean>(false);
  const [modelOptions, setModelOptions] = useState<string[]>([]);
  const [modelLoading, setModelLoading] = useState<boolean>(false);
  const [partOptions, setPartOptions] = useState<VehiclePart[]>([]);
  const [partLoading, setPartLoading] = useState<boolean>(false);
  const [totalDepreciation, setTotalDepreciation] = useState<number>(0);
  const [globalDepreciationPercentage, setGlobalDepreciationPercentage] = useState<number>(0);

  // New state variables for VAT
  const [vatChecked, setVatChecked] = useState<boolean>(false);
  const [vat, setVat] = useState<number>(0);
  const [netAmount, setNetAmount] = useState<number>(0);

  // **1. Define a State Variable for Total Discount**
  const [totalDiscount, setTotalDiscount] = useState<number>(0);

  // **2. Define State Variables for Parts Adjustment**
  const [partsAdjustment, setPartsAdjustment] = useState<number>(0);
  const [isPartsAdjustmentEditing, setIsPartsAdjustmentEditing] = useState<boolean>(false);
  const [partsAdjustmentInput, setPartsAdjustmentInput] = useState<string>('0');
  const [shopEstimate, setShopEstimate] = useState({
    id: 0,
    status: 0,
    table: {
      headers: [],
      rows: []
    }
  })

  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  const disableConditions = ["Taiwan Parts", "Surplus", "Replacement Parts"];
  const disableNames = [
    "Front Windshield Glass",
    "Rear Windshield Glass",
    "RH Front Door Glass",
    "RH Rear Door Glass",
    "LH Front Door Glass",
    "LH Rear Door Glass",
    "LH Quarter Glass",
    "RH Quarter Glass",
    "Back Door Glass",
    "Lift Gate Glass",
    "Sealant",
    "Damkit",
    "Freon Gas",
    "Pag Oil",
    "Engine Oil",
    "Brake Fluid",
    "Coolant",
    "Transmission Oil",
    "ATF",
    "Automatic Transmission Fluid",
    "Air Cleaner Element",
  ];

  const shouldDisableFields = (row: CartItem) => {
    return (
      disableConditions.includes(row.condition) ||
      disableNames.some(
        (disableName) => disableName.toLowerCase() === row.name.toLowerCase()
      )
    );
  };

  // Debounced fetchBrands to prevent excessive API calls
  const debouncedFetchBrands = useMemo(
    () =>
      debounce(async (search: string) => {
        setBrandLoading(true);
        try {
          const response = await fetchBrands(search);
          const brands: { brand: string }[] = response.data;
          const uniqueBrands = Array.from(new Set(brands.map((b) => b.brand))).sort();
          setBrandOptions(uniqueBrands);
        } catch (error) {
          console.error('Error fetching brands:', error);
        } finally {
          setBrandLoading(false);
        }
      }, 500),
    []
  );

  const policyPrefixes1 = ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"];
  const policyPrefixes2 = ["CV"];
  const partNames = ["battery", "tire", "ball joint", "tie rod", "shock absorber"];

  function policyStartsWith(policyNo: string, prefixes: string[]): boolean {
    return prefixes.some(prefix => policyNo.startsWith(prefix));
  }

  function vehicleIsOlderThan3Years(crDate: string): boolean {
    const currentDate = new Date();
    const vehicleDate = new Date(crDate);
    const diffYears = (currentDate.getTime() - vehicleDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
    return diffYears > 3;
  }

  function partNameContains(name: string, keywords: string[]): boolean {
    const lowerName = name.toLowerCase();
    return keywords.some(keyword => lowerName.includes(keyword.toLowerCase()));
  }

  // Function to recalculate depreciationFixed and depreciation
  const recalculateDepreciationFixed = (item: CartItem) => {
    let depreciationFixed = false;
    let depreciationRate = item.depreciation;

    if (vehicleIsOlderThan3Years(crDate) && partNameContains(item.name, partNames)) {
      if (policyStartsWith(policyNo, policyPrefixes1)) {
        depreciationRate = 45;
        depreciationFixed = true;
      } else if (policyStartsWith(policyNo, policyPrefixes2)) {
        depreciationRate = 50;
        depreciationFixed = true;
      }
    }

    if (depreciationFixed) {
      item.depreciation = depreciationRate;
      item.depreciationFixed = true;
      item.netAmount = calculateNetAmount(item.totalAmount, item.discount, item.depreciation);
    } else {
      item.depreciationFixed = false;
      // Apply global depreciation if necessary
      if (item.globalDepreciation) {
        item.depreciation = globalDepreciationPercentage;
        item.netAmount = calculateNetAmount(item.totalAmount, item.discount, item.depreciation);
      }
    }
  };

  useEffect(() => {
    debouncedFetchBrands('');
    return () => {
      debouncedFetchBrands.cancel();
    };
  }, [debouncedFetchBrands]);

  // Fetch models when brand changes
  useEffect(() => {
    const fetchModelOptions = async () => {
      if (!brand) {
        setModelOptions([]);
        return;
      }
      setModelLoading(true);
      try {
        const response = await fetchModels(brand);
        const models: { model: string }[] = response.data;
        const uniqueModels = Array.from(new Set(models.map((m) => m.model))).sort();
        setModelOptions(uniqueModels);
      } catch (error) {
        console.error('Error fetching models:', error);
      } finally {
        setModelLoading(false);
      }
    };

    fetchModelOptions();
    // Reset selections
    setModel(null);
    setModelInputValue(''); // Reset the input value
    setPart('');
    setPrice('');
    setPartOptions([]);
  }, [brand]);

  // Fetch parts when model changes
  useEffect(() => {
    const fetchPartOptions = async () => {
      if (!brand || !model) {
        setPartOptions([]);
        return;
      }
      setPartLoading(true);
      try {
        const response = await fetchParts(brand, model);
        const parts: VehiclePart[] = response.data.map((part: any) => ({
          brand: part.brand,
          model: part.model,
          parts: part.parts,
          price: part.price,
        }));
        setPartOptions(parts);
      } catch (error) {
        console.error('Error fetching parts:', error);
      } finally {
        setPartLoading(false);
      }
    };

    fetchPartOptions();
    // Reset part selection
    setPart('');
    setPrice('');
  }, [brand, model]);

  // Update price when part changes
  useEffect(() => {
    if (part) {
      const selectedPart = partOptions.find((p) => p.parts === part);
      if (selectedPart) {
        const tempPrice = globalData.role === "Imsi Encoder" || globalData.role === "Imsi Admin" ? "0.00" : selectedPart.price.toString();
        setPrice(tempPrice);
      } else {
        setPrice('');
      }
    } else {
      setPrice('');
    }
  }, [part, partOptions]);

  const { deId: deIdParam } = useParams<{ deId: string }>();
  const { transId: transIdParam } = useParams<{ transId: string }>();

  const fetchPartsFromTransaction = async () => {
    if (!deIdParam) return;
    try {
      const response = await getPartsByDeId(globalData?.accessToken, Number(deIdParam));
      const parts = Array.isArray(response.data) ? response.data : [];

      const updatedCart = parts.map((part: any) => {
        const status = part.status === "1" ? "Original" : "Surplus"; // Assuming status "1" means "Original"
        const depreciationPercentage = part.depreciation ? parseFloat(part.depreciation) : 0;
        const discountValue = part.discount ? parseFloat(part.discount) : 0;
        const amount = parseFloat(part.amount);
        const shopPrice = part.shopPrice ? parseFloat(part.shopPrice) : 0;
        const qty = parseFloat(part.qty);
        const shopAmount = part.shopAmount || false;
        const baseAmount = shopAmount ? shopPrice : amount;
        const totalAmount = baseAmount * qty;
        const netAmount = calculateNetAmount(totalAmount, discountValue, depreciationPercentage);

        return {
          id: part.id,
          deId: part.deId,
          transId: part.transId,
          name: part.name,
          amount: amount,
          qty: qty,
          condition: part.condition || 'Original', // Default to 'Original' if null
          globalDepreciation: part.globalDepreciation,
          totalAmount: totalAmount,
          shopPrice: shopPrice,
          depreciation: depreciationPercentage,
          discount: discountValue,
          netAmount: netAmount,
          shopAmount: shopAmount,
          remarks: part.remarks || '',
          saveDate: part.saveDate,
          isEditing: false, // Initialize as not editing
        };
      });

      setCart(updatedCart);
      // updateTotalDepreciation(updatedCart);

    } catch (error) {
      console.error('Error fetching parts:', error);
    }
  };

  useEffect(() => {
    if (deIdParam) {
      fetchPartsFromTransaction();
    }
  }, [deIdParam]);

  useEffect(() => {
    const total = cart.reduce((acc, curr) => acc + (curr.shopAmount ? curr.shopPrice : curr.amount) * curr.qty, 0).toFixed(2);
    onTotalAmountChange(total);
    updateCart(cart);
  }, [cart, onTotalAmountChange, updateCart]);

  useEffect(() => {
    const totalOfferAmount = cart.reduce((acc, curr) => acc + (curr.shopAmount ? curr.shopPrice : curr.amount) * curr.qty, 0);
    onTotalOfferAmountChange(totalOfferAmount);
    onTotalDepreciationChange(totalDepreciation);
    if (onTotalDiscountChange) {
      onTotalDiscountChange(totalDiscount);
    }
    if (onVatChange) {
      onVatChange(vat);
    }
  }, [cart, totalDepreciation, onTotalDiscountChange, onVatChange, onTotalOfferAmountChange, onTotalDepreciationChange]);

  // **2. Compute the Total Discount**
  useEffect(() => {
    const discount = cart.reduce((acc, item) => acc + (item.totalAmount * (item.discount / 100)), 0);
    setTotalDiscount(discount);

    // **Optional:** Notify parent component about the total discount
    if (onTotalDiscountChange) {
      onTotalDiscountChange(discount);
    }

    if (onVatChange) {
      onVatChange(vat);
    }
  }, [cart, onTotalDiscountChange, onVatChange]);

  // Helper functions
  const calculateNetAmount = (total: number, discount: number, depreciation: number) => {
    const discountedAmount = total * (discount / 100);
    const amountLessDiscount = total - discountedAmount;
    const depreciatedAmount = (amountLessDiscount * (depreciation / 100));
    return parseFloat((total - discountedAmount - depreciatedAmount).toFixed(2));
  };

  const calculateTotalAmount = (item: CartItem) => {
    const baseAmount = item.shopAmount ? item.shopPrice : item.amount;
    return baseAmount * item.qty;
  };

  const cleanPrice = (priceString: string) => {
    return priceString.replace(/[^\d.-]/g, '');
  };

  const handleBrandChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setBrand(value);
    setModel(null);
    setModelInputValue(''); // Reset the input value
    setPart('');
    setPrice('');
  };

  const handleModelChange = (_event: ChangeEvent<{}>, value: string | null) => {
    setModel(value);
    setModelInputValue(value || ''); // Ensure inputValue is synced
    setPart('');
    setPrice('');
  };

  const handlePartInputChange = (_event: any, value: string, reason: string) => {
    setPart(value);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handleQtyChange = (event: SelectChangeEvent<string>) => {
    setQty(event.target.value);
  };

  const handleAddToCart = async () => {
    if (isAddButtonDisabled) return;
    const amount = parseFloat(cleanPrice(price));
    const qtyNumber = parseFloat(qty);
    const shopAmount = false; // Initially, shopAmount is unchecked
    const baseAmount = shopAmount ? 0 : amount; // Since shopAmount is false, use amount
    const totalAmount = baseAmount * qtyNumber; // amount * qty
    const netAmount = calculateNetAmount(totalAmount, 0, 0); // Default discount and depreciation to 0

    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const localDate = new Date(currentDate.getTime() - timezoneOffset);
    const saveDate = localDate.toISOString().slice(0, -1);

    const newCartItem: CartItem = {
      deId: Number(deIdParam),
      transId: Number(transIdParam),
      name: part,
      amount: amount,
      qty: qtyNumber,
      condition: 'Original', // Default condition
      globalDepreciation: false,
      shopPrice: 0,
      totalAmount: totalAmount,
      depreciation: 0,
      discount: 0,
      netAmount: netAmount,
      shopAmount: shopAmount,
      remarks: '',
      saveDate: saveDate,
      isEditing: false,
    };

    recalculateDepreciationFixed(newCartItem);

    // Add part to the backend
    try {
      const response = await postParts(globalData?.accessToken, newCartItem);
      const savedPart = response?.data?.data;


      // Add the new part with its id to the cart
      setCart([...cart, { ...newCartItem, id: savedPart?.id }]);
      setQty('1'); // Reset qty to 1
    } catch (error) {
      console.error('Error adding part:', error);
    }
  };

  const handleDepreciationChange = (depreciationRate: number) => {
    setGlobalDepreciationPercentage(depreciationRate);
  };

  const handleDeleteFromCart = async (index: number) => {
    const itemToDelete = cart[index];

    if (itemToDelete.id) {
      try {
        await deletePartsById(globalData?.accessToken, itemToDelete.id);
        const newCart = [...cart];
        newCart.splice(index, 1);
        setCart(newCart);
      } catch (error) {
        console.error('Error deleting part:', error);
      }
    } else {
      console.error('Cannot delete item without an id');
    }
  };

  const handleCartItemChange = (
    index: number,
    field: keyof Omit<CartItem, 'id' | 'deId' | 'transId' | 'name' | 'saveDate' | 'isEditing'>,
    value: string | number | boolean
  ) => {
    const newCart = [...cart];
    const cartItem = { ...newCart[index] };

    // Update the specific field
    (cartItem as any)[field] = value;

    // Handle global depreciation checkbox
    if (field === 'globalDepreciation') {
      if (value === true) {
        // Set depreciation to globalDepreciationPercentage
        cartItem.depreciation = globalDepreciationPercentage;
      }
      // If unchecked, you might want to reset or keep the previous value
      // Here, we'll keep the current depreciation value
    }

    // Handle shopAmount checkbox
    if (field === 'shopAmount') {
      // Recalculate totalAmount based on shopAmount
      cartItem.totalAmount = calculateTotalAmount(cartItem);
    }

    // Recalculate totalAmount and netAmount if necessary
    if (field === 'qty' || field === 'amount' || field === 'shopPrice' || field === 'shopAmount') {
      cartItem.totalAmount = calculateTotalAmount(cartItem);
    }

    if (field === 'discount' || field === 'depreciation' || field === 'qty' || field === 'amount' || field === 'shopPrice' || field === 'shopAmount') {
      cartItem.netAmount = calculateNetAmount(cartItem.totalAmount, cartItem.discount, cartItem.depreciation);
    }

    if (field === 'condition') {
      if (shouldDisableFields(cartItem)) {
        cartItem.depreciation = 0;
        cartItem.globalDepreciation = false;
      }
    }

    if (field === 'globalDepreciation') {
      if (shouldDisableFields(cartItem)) {
        cartItem.depreciation = 0;
        cartItem.globalDepreciation = false;
      } else if (value === true) {
        cartItem.depreciation = globalDepreciationPercentage;
      }
    }

    // Prevent changing depreciation if depreciationFixed is true
    if (field === 'depreciation' && cartItem.depreciationFixed) {
      return; // Do not allow changes
    }

    recalculateDepreciationFixed(cartItem);

    newCart[index] = cartItem;
    setCart(newCart);
    // No need to call updateTotalDepreciation here since it's handled in a separate useEffect
  };

  const shouldDisableDepreciationField = (item: CartItem) => {
    return shouldDisableFields(item) || item.depreciationFixed;
  };

  const handleEditClick = (index: number) => {
    const newCart = [...cart];
    newCart[index].isEditing = true;
    setCart(newCart);
  };

  const handleSaveClick = async (index: number) => {
    const item = cart[index];
    if (!item.id) {
      console.error('Cannot save item without an id');
      return;
    }

    // Prepare the updated data
    const updatedData = {
      deId: deIdParam,
      transId: transIdParam,
      name: item.name,
      totalAmount: item.totalAmount,
      netAmount: item.netAmount,
      shopPrice: item.shopPrice,
      amount: item.amount,
      qty: item.qty,
      condition: item.condition,
      depreciation: item.depreciation,
      discount: item.discount,
      shopAmount: item.shopAmount,
      remarks: item.remarks,
      saveDate: new Date(new Date().getTime() + 8 * 60 * 60 * 1000).toISOString().slice(0, 23), // Add 8 hours and format to yyyy-MM-dd'T'HH:mm:ss.SSS
      globalDepreciation: item.globalDepreciation, // Assuming status '1' means depreciation applied
    };

    try {
      await updatePart(globalData?.accessToken, item.id, updatedData);

      // Recalculate totalAmount and netAmount
      const updatedTotalAmount = calculateTotalAmount(item);
      const updatedNetAmount = calculateNetAmount(item.totalAmount, item.discount, item.depreciation);

      // Update the cart
      const newCart = [...cart];
      newCart[index] = {
        ...item,
        totalAmount: updatedTotalAmount,
        netAmount: updatedNetAmount,
        globalDepreciation: updatedData.globalDepreciation,
        isEditing: false,
      };
      setCart(newCart);

      // Update total depreciation
      // No need to call updateTotalDepreciation here since it's handled in a separate useEffect
    } catch (error) {
      console.error('Error updating part:', error);
    }
  };

  const handleCancelClick = (index: number) => {
    const newCart = [...cart];
    newCart[index].isEditing = false;

    // Optionally, revert any changes by refetching the data or storing previous state
    // For simplicity, we'll assume changes are not persisted locally and revert to initial state
    // Alternatively, implement a way to store the original data before editing

    setCart(newCart);
  };

  const isAddButtonDisabled = useMemo(() => {
    const isPriceValid = price.trim() !== '' && !isNaN(parseFloat(price));
    const isQtyValid = qty.trim() !== '' && !isNaN(parseFloat(qty)) && parseFloat(qty) > 0;
    return part.trim() === '' || !isPriceValid || !isQtyValid;
  }, [part, price, qty]);

  // Handle Global Depreciation Change
  useEffect(() => {
    const updatedCart = cart.map((item) => {
      if (item.globalDepreciation) {
        return {
          ...item,
          depreciation: globalDepreciationPercentage,
          netAmount: calculateNetAmount(item.totalAmount, item.discount, globalDepreciationPercentage),
        };
      }
      return item;
    });
    setCart(updatedCart);
  }, [globalDepreciationPercentage]);

  // Updated updateTotalDepreciation to include VAT
  const updateTotalDepreciationEffect = () => {
    let dep = cart.reduce((acc, item) => {
      const depPercentage = item.globalDepreciation ? globalDepreciationPercentage : item.depreciation;
      return acc + ((item.totalAmount - (item.totalAmount * (item.discount / 100))) * (depPercentage / 100));
    }, 0);
    if (vatChecked) {
      dep += cart.reduce((acc, item) => acc + (vat * (item.depreciation / 100)), 0);
    }
    setTotalDepreciation(dep);
  };

  // Compute VAT when VAT checkbox or cart changes
  useEffect(() => {
    const totalAfterDiscount = cart.reduce((acc, item) => acc + (item.totalAmount - (item.totalAmount * (item.discount / 100))), 0);
    if (vatChecked) {
      const computedVat = totalAfterDiscount * 0.12;
      setVat(computedVat);
    } else {
      setVat(0);
    }
  }, [vatChecked, cart]);

  // **2. Update Total Depreciation Effect**
  useEffect(() => {
    updateTotalDepreciationEffect();
  }, [cart, globalDepreciationPercentage, vatChecked, vat]);

  // Compute Net Amount whenever totalAfterDiscount, VAT, or totalDepreciation changes
  useEffect(() => {
    const totalAfterDiscount = cart.reduce((acc, item) => acc + (item.totalAmount - (item.totalAmount * (item.discount / 100))), 0);
    const computedNetAmount = (totalAfterDiscount + vat + partsAdjustment) - totalDepreciation;
    setNetAmount(computedNetAmount);
  }, [cart, vat, totalDepreciation, partsAdjustment]);

  const calculateTotal = () => {
    const total = cart.reduce((acc, curr) => acc + (curr.shopAmount ? curr.shopPrice : curr.amount) * curr.qty, 0);
    return formatNumber(total);
  };

  // **3. Functions to Handle Parts Adjustment Editing**

  // Function to initiate editing
  const handleEditPartsAdjustment = () => {
    setPartsAdjustmentInput(partsAdjustment.toString());
    setIsPartsAdjustmentEditing(true);
  };

  // Function to save the edited parts adjustment
  const handleSavePartsAdjustment = async () => {
    // Validate the input
    const parsedValue = parseFloat(partsAdjustmentInput);
    if (isNaN(parsedValue)) {
      // Optionally, show an error message to the user
      alert("Please enter a valid number for Parts Adjustment.");
      return;
    }

    // Update the state
    setPartsAdjustment(parsedValue);
    setIsPartsAdjustmentEditing(false);

    // Make the API call
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deIdParam}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
        body: JSON.stringify({
          partsAdjustment: parsedValue.toFixed(2),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update parts adjustment');
      }

      // Optionally, handle response data

    } catch (error) {
      console.error('Error updating parts adjustment:', error);
      // Optionally, revert the state or notify the user
      alert("Failed to update Parts Adjustment. Please try again.");
    }
  };

  // Function to cancel editing
  const handleCancelPartsAdjustment = () => {
    setIsPartsAdjustmentEditing(false);
  };

  // Function to handle input change
  const handlePartsAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartsAdjustmentInput(event.target.value);
  };

  useEffect(() => {
    const fetchShopEstimate = async () => {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      ).then((res: any) => {
        const data: IShopEstimate = res.data.data;

        const tblShopItemsJSON = data.tableItems
          ? JSON.parse(data.tableItems)
          : null;

        if (tblShopItemsJSON) {
          const tblShopItemRows = tblShopItemsJSON.rows.map((row: any) => {
            // Generate a unique ID for each row
            const id = uuidv4();

            // Handle the case where row is empty
            if (row.length === 0) {
              return tblShopItemsJSON.header.reduce(
                (acc: any, key: any) => {
                  acc[key] = "";
                  return acc;
                },
                { id }
              ); // Include unique id in the object
            }

            return row.reduce(
              (acc: any, value: any, index: any) => {
                acc[tblShopItemsJSON.header[index]] = value;
                return acc;
              },
              { id }
            ); // Include unique id in the object
          });



          setShopEstimate({
            id: data.id || 0,
            status: data.status || 0,
            table: {
              headers: tblShopItemsJSON.header,
              rows: tblShopItemRows,
            }
          });
        }
      }).catch((err: any) => {

      })
    }

    fetchShopEstimate();
  }, [transId])

  const handleTransferParts = async () => {

    const filteredParts = shopEstimate.table.rows
      .filter((item: any) => item["Material cost"] !== "")
      .map((item: any) => ({
        name: item["Scope of work"],
        shopPrice: parseFloat(cleanPrice(item["Material cost"])),
        amount: 0,
        qty: 1
      }));
    // TODO: add items in parts table

    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/transfer-parts?transId=${transId}&deId=${deId}`,
      filteredParts,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    ).then((res: any) => {

      fetchPartsFromTransaction();
    }).catch((err: any) => {

    })
  }

  const shouldDisableGlobalDepreciationField = (item: CartItem) => {
    return item.depreciationFixed;
  };

  return (
    <div>
      <br /><br />
      <Typography variant="h5" className="tp-text-header-primary" textAlign="center">
        List of Parts
      </Typography>
      <br />
      <Grid container spacing={2}>
        {/* Brand Selector */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            freeSolo
            options={brandOptions}
            loading={brandLoading}
            value={brand}
            onChange={handleBrandChange}
            onInputChange={(event, newInputValue) => {
              setBrand(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.toLowerCase() === (value || '').toLowerCase()}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicle Brand"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {brandLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>

        {/* Model Selector */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            freeSolo
            options={modelOptions}
            loading={modelLoading}
            value={model}
            inputValue={modelInputValue} // Bind inputValue
            onChange={handleModelChange}
            onInputChange={(event, newInputValue) => {
              setModelInputValue(newInputValue); // Update inputValue separately
            }}
            disabled={!brand}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vehicle Model"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {modelLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>

        {/* Parts Selector */}
        <Grid item xs={12} md={7}>
          <Autocomplete
            freeSolo
            options={partOptions.map((p) => p.parts)}
            loading={partLoading}
            value={part}
            onChange={(event, newValue) => {
              setPart(newValue || '');
            }}
            onInputChange={handlePartInputChange}
            disabled={!brand || !model}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Parts"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {partLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>

        {/* Amount Display (Editable) */}
        <Grid item xs={12} md={3}>
          <TextField
            label="Amount"
            value={price}
            onChange={handlePriceChange}
            type="text"
            size="small"
            error={price.trim() !== '' && isNaN(parseFloat(price))}
            helperText={price.trim() !== '' && isNaN(parseFloat(price)) ? "Invalid number" : ""}
            fullWidth
          />
        </Grid>

        {/* Quantity Input */}
        <Grid item xs={12} md={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Qty</InputLabel>
            <Select
              value={qty || '1'}
              onChange={handleQtyChange}
              label="Qty"
            >
              {Array.from({ length: 40 }, (_, i) => i + 1).map((num) => (
                <MenuItem key={num} value={num.toString()}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Add to Cart Button */}
        <Grid item xs={12} sm={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToCart}
            disabled={isAddButtonDisabled}
            style={{ width: "600px" }}
          >
            Add Part
          </Button>
        </Grid>
      </Grid>

      <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
        <Grid container spacing={2} alignItems="center">
          {/* Global Depreciation Selector */}
          <Grid item xs={12} md={4}>
            {perilType !== "Third Party" && (
              <Depreciation
                policyNo={policyNo}
                crDate={crDate}
                onDepreciationChange={handleDepreciationChange}
              />
            )}
          </Grid>


          {/* VAT Checkbox */}
          <Grid item xs={12} md={3} sx={{ display: 'flex' }}>
            <Checkbox
              checked={vatChecked}
              onChange={(e) => setVatChecked(e.target.checked)}
              color="primary"
              sx={{ alignItems: 'center' }}
            />
            <Typography sx={{ marginTop: 1.5 }}>VAT (12%)</Typography>
          </Grid>

          <Grid item xs={12} md={5} textAlign="right">
            <Button color="primary" size='small' variant='contained' startIcon={<SendIcon />} onClick={handleTransferParts}>
              Transfer
            </Button>
          </Grid>
        </Grid>
      </Card>

      {/* Parts Table */}
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Qty</TableCell>
              <TableCell >Part Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell style={{ textAlign: "center" }}>Shop Price</TableCell>
              <TableCell style={{ textAlign: "center" }}>Use Shop Price</TableCell>
              <TableCell style={{ textAlign: "center" }}>Offer Price</TableCell>
              <TableCell style={{ textAlign: "center" }}>Total Amount</TableCell>

              {perilType !== "Third Party" && (
                <TableCell style={{ textAlign: "center" }}>Use Global Depreciation</TableCell>
              )}
              {perilType !== "Third Party" && (
                <TableCell style={{ textAlign: "center" }}>Depreciation (%)</TableCell>
              )}
              <TableCell style={{ textAlign: "center" }}>Discount (%)</TableCell>
              <TableCell style={{ textAlign: "center" }}>Net Amount</TableCell>

              <TableCell>Remarks</TableCell>
              <TableCell width={120}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart.map((item, index) => (
              <TableRow key={index}>
                {/* Qty - Editable */}
                <TableCell width={80}>
                  {item.isEditing ? (
                    <TextField
                      type="number"
                      style={{ width: "50px" }}
                      value={item.qty}
                      onChange={(e) => handleCartItemChange(index, 'qty', parseFloat(e.target.value))}
                      inputProps={{ min: 1 }}
                      size="small"
                    />
                  ) : (
                    item.qty
                  )}
                </TableCell>

                {/* Part Name - Non-editable */}
                <TableCell width={200}>{item.name}</TableCell>

                {/* Condition - Editable Dropdown */}
                <TableCell>
                  {item.isEditing ? (
                    <Select
                      value={item.condition}
                      onChange={(e) => handleCartItemChange(index, 'condition', e.target.value)}
                      size="small"
                    >
                      <MenuItem value="Original">Original</MenuItem>
                      <MenuItem value="Replacement Parts">Replacement Parts</MenuItem>
                      <MenuItem value="Surplus">Surplus</MenuItem>
                      <MenuItem value="Taiwan Parts">Taiwan Parts</MenuItem>
                      <MenuItem value="Not Standard Part">Not Standard Part</MenuItem>
                      <MenuItem value="No Price Established">No Price Established</MenuItem>
                      <MenuItem value="Estimated Price">Estimated Price</MenuItem>
                      <MenuItem value="Subject to Availability">Subject to Availability</MenuItem>
                      <MenuItem value="As per sample">As per sample</MenuItem>
                      <MenuItem value="No stock">No stock</MenuItem>
                      <MenuItem value="Standard operating procedure when replacing parts">Standard operating procedure when replacing parts</MenuItem>
                    </Select>
                  ) : (
                    item.condition
                  )}
                </TableCell>

                {/* Shop Price - Editable */}
                <TableCell>
                  {item.isEditing ? (
                    <TextField
                      type="number"
                      style={{ width: "150px" }}
                      value={item.shopPrice}
                      onChange={(e) => handleCartItemChange(index, 'shopPrice', parseFloat(e.target.value))}
                      inputProps={{ min: 0 }}
                      size="small"
                    />
                  ) : (
                    formatNumber(item.shopPrice)
                  )}
                </TableCell>

                {/* Shop Amount - Editable Checkbox */}
                <TableCell>
                  {item.isEditing ? (
                    <Checkbox
                      checked={item.shopAmount}
                      onChange={(e) => handleCartItemChange(index, 'shopAmount', e.target.checked)}
                      color="primary"
                    />
                  ) : (
                    <Checkbox checked={item.shopAmount} disabled />
                  )}
                </TableCell>

                {/* Amount - Editable */}
                <TableCell>
                  {item.isEditing ? (
                    <TextField
                      type="number"
                      value={item.amount}
                      style={{ width: "150px" }}
                      onChange={(e) => handleCartItemChange(index, 'amount', parseFloat(e.target.value))}
                      inputProps={{ min: 0 }}
                      size="small"
                    />
                  ) : (
                    formatNumber(item.amount)
                  )}
                </TableCell>

                {/* Total Amount - Non-editable */}
                <TableCell>{formatNumber(item.totalAmount)}</TableCell>

                {/* Use Global Depreciation - Editable Checkbox */}


                {perilType !== "Third Party" && (
                  <TableCell>
                    {!shouldDisableFields(item) && (
                      item.isEditing ? (
                        <Checkbox
                          checked={item.globalDepreciation}
                          onChange={(e) => handleCartItemChange(index, 'globalDepreciation', e.target.checked)}
                          color="primary"
                          disabled={shouldDisableGlobalDepreciationField(item)}
                        />
                      ) : (
                        <Checkbox checked={item.globalDepreciation} disabled />
                      )
                    )}
                  </TableCell>
                )}

                {perilType !== "Third Party" && (

                  <TableCell>
                    {shouldDisableDepreciationField(item) ? (
                      `${item.depreciation}%`
                    ) : (
                      item.isEditing ? (
                        <Select
                          value={item.depreciation != null ? item.depreciation : 0}
                          onChange={(e) => handleCartItemChange(index, 'depreciation', parseFloat(e.target.value.toString()))}
                          size="small"
                          disabled={item.globalDepreciation}
                        >
                          {Array.from({ length: 21 }, (_, i) => i * 5).map((percent) => (
                            <MenuItem key={percent} value={percent}>
                              {percent}%
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        `${item.depreciation}%`
                      )
                    )}
                  </TableCell>

                )}


                {/* Discount - Editable Two Decimal Input */}
                <TableCell>
                  {item.isEditing ? (
                    <TextField
                      type="number"
                      value={item.discount}
                      onChange={(e) => handleCartItemChange(index, 'discount', parseFloat(e.target.value))}
                      inputProps={{ min: 0, max: 100, step: 0.01 }}
                      size="small"
                    />
                  ) : (
                    `${item.discount}%`
                  )}
                </TableCell>

                {/* Net Amount - Non-editable */}
                <TableCell>{formatNumber(item.netAmount)}</TableCell>

                {/* Remarks - Editable Text Input */}
                <TableCell>
                  {item.isEditing ? (
                    <TextField
                      value={item.remarks}
                      onChange={(e) => handleCartItemChange(index, 'remarks', e.target.value)}
                      size="small"
                      inputProps={{ maxLength: 25 }}
                    />
                  ) : (
                    item.remarks
                  )}
                </TableCell>

                {/* Action - Edit/Delete or Save/Cancel Icons */}
                <TableCell>
                  {item.isEditing ? (
                    <div style={{ width: "100px" }}>
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveClick(index)}
                        aria-label="save"
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleCancelClick(index)}
                        aria-label="cancel"
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditClick(index)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteFromCart(index)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* **4. Totals Display Including Parts Adjustment** */}

      <Grid container marginTop="25px">
        <Grid item md={5} />
        <Grid item md={2}>
          <Typography variant="body1">
            Total Parts Amount :
          </Typography>
        </Grid>
        <Grid item md={3}>
          <b style={{ color: "navy" }}>₱ {formatNumber(cart.reduce((acc, item) => acc + (item.shopAmount ? item.shopPrice : item.amount) * item.qty, 0))}</b>
        </Grid>
      </Grid>

      {vatChecked &&
        <Grid container marginTop="25px">
          <Grid item md={5} />
          <Grid item md={2}>
            <Typography variant="body1">
              Total VAT (12%) :
            </Typography>
          </Grid>
          <Grid item md={3}>
            <b style={{ color: "navy" }}>₱ {formatNumber(vat)}</b>
          </Grid>
        </Grid>
      }

      <Grid container>
        <Grid item md={5} />
        <Grid item md={2}>
          <Typography variant="body1">
            Total Discount :
          </Typography>
        </Grid>
        <Grid item md={3}>
          <b style={{ color: "navy" }}>₱ {formatNumber(totalDiscount)}</b>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={5} />
        <Grid item md={2}>
          <Typography variant="body1">
            Total Depreciation :
          </Typography>
        </Grid>
        <Grid item md={3}>
          <b style={{ color: "navy" }}>₱ {formatNumber(totalDepreciation)}</b>
        </Grid>
      </Grid>

      {/* Parts Adjustment Section */}
      {/* {isPartsAdjustmentEditing ? (
        <Grid container spacing={2} marginTop="10px" marginBottom="10px">
          <Grid item xs={12} md={3} lg={5} />
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Price Adjustments (Parts)"
              value={partsAdjustmentInput}
              onChange={handlePartsAdjustmentChange}
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 0 } }}
            />
            <span style={{ marginLeft: "10px" }}>
              <IconButton color="primary" onClick={handleSavePartsAdjustment} aria-label="save">
                <CheckIcon />
              </IconButton>
              <IconButton color="error" onClick={handleCancelPartsAdjustment} aria-label="cancel">
                <CloseIcon />
              </IconButton>
            </span>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={5} />
          <Grid item md={2}>
            <Typography variant="body1">
              Additional Costs :
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body1">
              <b style={{ color: "navy" }}>₱ {formatNumber(partsAdjustment)}</b>

              <IconButton color="primary" onClick={handleEditPartsAdjustment} aria-label="edit">
                <EditIcon color='primary' />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>
      )} */}

      <Grid container>
        <Grid item md={5} />
        <Grid item md={2}>
          <Typography variant="body1">
            Total Net Amount :
          </Typography>
        </Grid>
        <Grid item md={3}>
          <b style={{ color: "blue" }}>₱ {formatNumber(netAmount)}</b>
        </Grid>
      </Grid>
      {/* Net Amount including Parts Adjustment */}
      {/* <Typography variant="body1" textAlign="center">
          Net Amount: ₱ <b style={{ color: "blue" }}>{formatNumber(netAmount)}</b>
        </Typography> */}
      {/* <Typography variant="h5" textAlign="center">
        Total Labor: <b style={{ color: "blue" }}>₱ {formatNumber(netAmount)}</b>
      </Typography> */}
    </div>
  );
};

export default ListOfParts;
