import * as React from 'react';
import TaskCard from '../components/TaskCard'
import { Container } from '@mui/material';
import TaskCardAdmin from '../components/TaskCardAdmin';
import { GlobalDataContext } from "../contexts/GlobalDataContext";

const claimsData = [
    {
        "id": 1,
        "title": "User Management",
        "slug": "user-management",
        "description": "Monitor, add, edit and remove user",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
   
    {
        "id": 3,
        "title": "User Task Management",
        "slug": "user-task-management",
        "description": "Manage user tasks",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 6,
        "title": "ACAP Task Management",
        "slug": "acap-task-management",
        "description": "Manually assign a claim in Document Validation",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 4,
        "title": "Repair Shop Management",
        "slug": "repair-shop-management",
        "description": "Manage repair shops",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 5,
        "title": "Labor Rates Management",
        "slug": "labor-rates-management",
        "description": "Manage labor rates",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    }
   
]

const helpdeskData = [
    {
        "id": 1,
        "title": "User Management",
        "slug": "user-management",
        "description": "Monitor, add, edit and remove user",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
   
    {
        "id": 3,
        "title": "User Task Management",
        "slug": "user-task-management",
        "description": "Manage user tasks",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 6,
        "title": "ACAP Task Management",
        "slug": "acap-task-management",
        "description": "Manually assign a claim in Document Validation",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 4,
        "title": "Repair Shop Management",
        "slug": "repair-shop-management",
        "description": "Manage repair shops",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 5,
        "title": "Labor Rates Management",
        "slug": "labor-rates-management",
        "description": "Manage labor rates",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 2,
        "title": "Audit Logs",
        "slug": "activity-trail",
        "description": "Monitor user activity logs",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    }
   
]

const adminData = [
    {
        "id": 1,
        "title": "User Management",
        "slug": "user-management",
        "description": "Monitor, add, edit and remove user",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 2,
        "title": "Audit Logs",
        "slug": "activity-trail",
        "description": "Monitor user activity logs",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    }
   
]

export function HomeData() {
    const { globalData } = React.useContext(GlobalDataContext);
    const [projects, setProjects] = React.useState<any>([]);
    
    React.useEffect(() => {
        switch (globalData?.role) {
            case "Admin":
                setProjects(adminData);
                break;
            case "Claims Admin":
                setProjects(claimsData);
                break;
            case "Help Desk":
                setProjects(helpdeskData);
                break;
            default:
                break;
        }
    },[])

    return (
        <div>
            {
                projects.map((task: any, index: number) => (
                    <TaskCardAdmin key={index} task={task}/>
                ))
            }
        </div>
    )
} 


export default function HomePage() {

    return (
        <Container maxWidth="lg" sx={{
            mt: 12
        }}>
            <HomeData/>

        {/* <ResetMfaComponent/> */}
        </Container>
    )
}