// contexts/BusinessRulesContext.tsx

import axios from "axios";
import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";
import { filteredArray } from "../utils/stringUtils";
import { NotificationContext } from "./NotificationContext";
import { access } from "fs";

export interface IRules {
  id: number; // Ensure each rule has a unique id
  description: string;
  validated: boolean;
  override?: boolean;
  comment?: string;
  user?: string;
  updatedAt?: string;
}

export interface ITaskBusinessRules {
  id: number;
  docId: number;
  transId: number;
  saveDate: string;
  documentType: string;
  rules: IRules[];
  passed: boolean;
}

interface IBusinessRulesState {
  taskBusinessRules: ITaskBusinessRules[];
  rules: IRules[];
  isLoading: boolean;
}

// Define a type for the context value
interface IBusinessRulesContextType {
  businessRules: IBusinessRulesState;
  setBusinessRules: React.Dispatch<React.SetStateAction<IBusinessRulesState>>;
  fetchTaskBusinessRules: (accessToken: string, transId: number, taskType: string) => Promise<void>;
  fetchBusinessRules: (accessToken: string,docId: number, transId: number, taskType: string, documentType: string) => Promise<void>;
  validateBusinessRules: (
    accessToken: string,
    endpoint: string,
    requestBody: any,
    transId: number,
    taskType: string
  ) => Promise<void>;
  overrideBusinessRules: (accessToken: string,docId: number, transId: number, taskType: string, documentType: string, updatedRules: IRules[]) => Promise<void>;
}

// Create the context with default values
const BusinessRulesContext = createContext<IBusinessRulesContextType | undefined>(undefined);

// Create a provider component
type BusinessRulesProviderProps = {
  children: ReactNode;
};

export default function BusinessRulesStore({ children }: BusinessRulesProviderProps) {
  const [businessRules, setBusinessRules] = useState<IBusinessRulesState>({
    taskBusinessRules: [],
    rules: [],
    isLoading: false,
  });
  const { notification, setNotification } =
    React.useContext(NotificationContext);

  // Memoize fetchTaskBusinessRules using useCallback
  const fetchTaskBusinessRules = useCallback(async (accessToken:string, transId: number, taskType: string) => {
    // if (!accessToken) {
    //   throw new Error('Access token is required');
    // }
    setBusinessRules((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/business-rules/all?transId=${transId}&taskType=${taskType}`
        // {
        //   headers: {
        //                  'Authorization': `Bearer ${accessToken}`
        //                }
        //  }
      );

      const businessRulesResponse = res?.data?.data;
      const formattedBusinessRules: ITaskBusinessRules[] = Array.isArray(businessRulesResponse)
        ? businessRulesResponse.map((item: any) => ({
          ...item,
          rules: item.rules ? JSON.parse(item.rules) : item.rules,
        }))
        : [];

      setBusinessRules((prev) => ({
        ...prev,
        taskBusinessRules: formattedBusinessRules,
        isLoading: false,
      }));

    } catch (err: any) {
      console.error("Error fetching task business rules:", err);
      setBusinessRules((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  }, []);

  // Memoize fetchBusinessRules using useCallback
  const fetchBusinessRules = useCallback(
    async (accessToken: string, docId: number, transId: number, taskType: string, documentType: string) => {
     
      setBusinessRules((prev) => ({
        ...prev,
        isLoading: true,
      }));
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/business-rules?docId=${docId}&transId=${transId}&taskType=${taskType}&documentType=${documentType}`,
          {
            headers: {
                           'Authorization': `Bearer ${accessToken}`
                         }
           }
        );

        const ruleItems: IRules[] = res?.data?.data?.rules
          ? JSON.parse(res?.data?.data?.rules)
          : [];

        const tfRuleItems = ruleItems.map((item) => {
          return {
            ...item,
            override: item.override ? item.override : false,
            comment: item.comment ? item.comment : ""
          }
        })



        setBusinessRules((prev) => ({
          ...prev,
          rules: tfRuleItems,
          isLoading: false,
        }));
      } catch (err: any) {
        console.error("Error fetching business rules:", err);
        setBusinessRules((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    },
    []
  );

  // Memoize validateBusinessRules using useCallback
  const validateBusinessRules = useCallback(
    async (
      accessToken: string,
      endpoint: string,
      requestBody: any,
      transId: number,
      taskType: string
    ) => {
     
      setBusinessRules((prev) => ({
        ...prev,
        isLoading: true,
      }));
     
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, requestBody,
          {
            headers: {
                           'Authorization': `Bearer ${accessToken}`
                         }
           }
        );

        const ruleItems: IRules[] = res?.data?.data?.rules
          ? JSON.parse(res?.data?.data?.rules)
          : [];

        const tfRuleItems = ruleItems.map((item) => {
          return {
            ...item,
            override: item.override ? item.override : false,
            comment: item.comment ? item.comment : ""
          }
        })



        setBusinessRules((prev) => ({
          ...prev,
          rules: tfRuleItems,
          isLoading: false,
        }));

        fetchTaskBusinessRules(accessToken, transId, taskType);
      } catch (err: any) {
        console.error("Error validating business rules:", err);
        setBusinessRules((prev) => ({
          ...prev,
          isLoading: false,
        }));
        // alert(err.response.data.message)
      }
    },
    [fetchTaskBusinessRules]
  );

  const overrideBusinessRules = async (accessToken:string, docId: number, transId: number, taskType: string, documentType: string, updatedRules: IRules[]) => {

    
    // transform business rules into request
    const isPassed = updatedRules.every(item => item.validated);
    
    const tfRequestData = {
      docId,
      transId,
      taskType,
      documentType,
      rules: updatedRules,
      passed: isPassed
    }


    // send request to the api
    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/override-business-rules`,
      tfRequestData,
      {
        headers: {
                       'Authorization': `Bearer ${accessToken}`
                     }
       }
    ).then( async (res: any) => {
        console.log("brTask")

        const resp = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/business-rules/all?transId=${transId}&taskType=${taskType}`,
          {
            headers: {
                           'Authorization': `Bearer ${accessToken}`
                         }
           }
        );
  
        const businessRulesResponse = resp?.data?.data;
        const formattedBusinessRules: ITaskBusinessRules[] = Array.isArray(businessRulesResponse)
          ? businessRulesResponse.map((item: any) => ({
            ...item,
            rules: item.rules ? JSON.parse(item.rules) : item.rules,
          }))
          : [];
  
        setBusinessRules((prev) => ({
          ...prev,
          taskBusinessRules: formattedBusinessRules,
          isLoading: false,
        }));
    }).catch((err: any) => {

    });
  }

  return (
    <BusinessRulesContext.Provider
      value={{
        businessRules,
        setBusinessRules,
        fetchTaskBusinessRules,
        fetchBusinessRules,
        validateBusinessRules,
        overrideBusinessRules
      }}
    >
      {children}
    </BusinessRulesContext.Provider>
  );
}

// Create a custom hook to use the BusinessRulesContext
export const useBusinessRules = (): IBusinessRulesContextType => {
  const context = useContext(BusinessRulesContext);
  if (!context) {
    throw new Error("useBusinessRules must be used within a BusinessRulesProvider");
  }
  return context;
};
