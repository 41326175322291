import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { CSVLink } from "react-csv";
import { generateFileName } from "../../utils/stringUtils";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../api/FormatDate";
import { AuditTrailTableMainState } from "../../types/AuditTrailTableMainState";
import GetAppIcon from '@mui/icons-material/GetApp';

interface Column {
  id:
  | "id"
  | "event"
  | "description"
  | "user"
  | "fullName"
  | "datetime";
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: string) => string;
}

const columns: readonly Column[] = [
  { id: "id", label: "ID", minWidth: 100, align: "center" },
  {
    id: "event",
    label: "Event/Action",
    minWidth: 170,
    align: "center",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 300,
    align: "center",
  },
  {
    id: "user",
    label: "Username",
    minWidth: 170,
    align: "center",
  },
  {
    id: "fullName",
    label: "Full Name",
    minWidth: 170,
    align: "center",
  },
  {
    id: "datetime",
    label: "Date and Time",
    minWidth: 300,
    align: "center",
    format: (value: string) => formatDate(value),
  },
];

interface Data {
  id: number;
  event: string;
  description: string;
  user: string;
  fullName: string;
  datetime: string;
}

interface AuditTrailTableProps {
  rows: Data[];
  mainState: AuditTrailTableMainState;
  setMainState: (mainState: AuditTrailTableMainState) => void;
  getLogs: (page: number, size: number, searchKey: string) => void;
}

export default function AdminTrailTable({
  rows,
  mainState,
  setMainState,
  getLogs,
}: AuditTrailTableProps) {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [searchKey, setSearchKey] = React.useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainState({
      ...mainState,
      pagination: {
        ...mainState.pagination,
        searchKey: event.target.value
      }
    })
  };

  const handleOnSearch = () => {

    getLogs(mainState.pagination.page, mainState.pagination.size, mainState.pagination.searchKey);
  };

  const handleInputClear = () => {
    setMainState({
      ...mainState,
      pagination: {
        ...mainState.pagination,
        searchKey: ""
      }
    })
    getLogs(mainState.pagination.page, mainState.pagination.size, "");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    getLogs(newPage, mainState.pagination.size, mainState.pagination.searchKey);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    getLogs(
      0,
      parseInt(event.target.value, 10),
      mainState.pagination.searchKey
    );
  };

  return (
    <Card>
      <Stack sx={{ py: "1em", px: "2em" }}>
        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} spacing={1}>
          <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} spacing={1}>
            <TextField
              id="input-with-sx"
              label="Search"
              variant="standard"
              value={mainState.pagination.searchKey}
              onChange={handleInputChange}
              sx={{ width: 300 }}
            />
            <Button className="tp-custom-button-primary" variant="contained" sx={{ ml: 1 }} onClick={handleOnSearch} startIcon={<SearchIcon />}>
              Search
            </Button>
            {mainState.pagination.searchKey.length > 0 && (
              <Button color="warning" variant="contained" sx={{ ml: 1 }} onClick={handleInputClear} startIcon={<CloseIcon />}>
                Clear
              </Button>
            )}
          </Stack>
          <Box>
             <CSVLink
                          filename={`${generateFileName(`AdminLogs`)}.csv`}
                          target="_blank"
                          data={rows}
                        >
            <Tooltip title="Export to CSV">
              <IconButton aria-label="export-csv" size="medium">
                <GetAppIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            </CSVLink>
          </Box>
        </Stack>
      </Stack>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      backgroundColor: "rgb(177, 17, 22)",
                      color: "white",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {mainState.isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 2, // Optional: Adds some padding around the loader
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "string"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 100, 1000]}
          component="div"
          count={mainState.pagination.total}
          rowsPerPage={mainState.pagination.size}
          page={mainState.pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Card>
  );
}
