import React from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IInsurerReportData } from "../types/InsurerReport";
import { getFilenameInUrl, getFormatDate } from "../utils/stringUtils";

function InsurerReportPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deId = queryParams.get("deId");
  const [data, setData] = React.useState([]);

  const [fileErrorOpen, setFileErrorOpen] = React.useState(false);

  const handleOpenDocument = (url: string) => {
    if (!url || url.trim() === "") {
      // If fileUrl is null or blank, show the error modal
      setFileErrorOpen(true);
    } else {
      // If fileUrl is valid, open it in a new tab
      window.open(url, "_blank");
    }
  };

  const handleFileErrorClose = () => {
    setFileErrorOpen(false);
  };

  const fetchInsurerReports = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/insurer-report/${deId}`
      )
      .then((res: any) => {
        setData(res?.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    fetchInsurerReports();
  }, []);

  return (
    <>
      <Container maxWidth={"lg"} sx={{ my: 12 }}>
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent>
            <Typography variant="h6" className="tp-text-header-primary">
              Completed In-House Survey
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br/>
            <Accordion
              sx={{
                backgroundColor: "#1C1364",
                color: "white",
              }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Completed In-House Survey History</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {data &&
                  data.map((item: IInsurerReportData) => (
                    <Grid container marginBottom={2}>
                      <Grid item xs={4} sm={4} md={4}>
                        {/* {formatDate(item.saveDate)} */}
                        <Typography
                          sx={{ justifySelf: "center", paddingTop: "7px" }}
                        >
                          {getFormatDate(item?.url)} :{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="contained"
                            color="warning"
                            sx={{ backgroundColor: "white", color: "#1C1364" }}
                            onClick={() => handleOpenDocument(item.url)}
                          >
                            {getFilenameInUrl(item.url)}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Container>

      <Dialog
        open={fileErrorOpen}
        onClose={handleFileErrorClose}
        aria-labelledby="file-error-dialog-title"
      >
        <DialogTitle id="file-error-dialog-title">File Error</DialogTitle>
        <DialogContent>
          <Typography>File error. Please contact Admin.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileErrorClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InsurerReportPage;
