import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Skeleton,
  Box,
  CircularProgress,
  Chip,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  SelectableRows,
  Responsive,
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import React from "react";
// import users from '../data/users.json';
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { toTitleCase } from "../../api/utils";
import { formatDate } from "../../api/FormatDate";
import axios from "axios";
import CustomToolbarSelect from "./table_components/CustomToolbarSelect";

export default function UserManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: ["Claims Admin","Admin"].includes(globalData?.role) ? "single" : "none",
    responsive: "standard",
    // resizableColumns: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} data={rows} />
    ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const statusMap: { [key: string]: string } = {
    null: "Not Active",
    "0": "Not Active",
    "1": "Active",
  };

  const columns = [
    {
      name: "userName",
      label: "Username",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "roleName",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "status_display",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => value,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
        filterOptions: {
          names: Object.keys(statusMap).map((key) => statusMap[key]),
          logic(status: any, filterVal: any) {
            return !filterVal.includes(statusMap[status]);
          },
          display: (
            filterList: any,
            onChange: any,
            index: any,
            column: any
          ) => (
            <div>
              <label>Status</label>
              <select
                onChange={(event) => {
                  filterList[index] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: "100%" }}
              >
                <option value="">All</option>
                {Object.entries(statusMap).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          ),
        },
      },
    },
    {
      name: "lastLogin",
      label: "Last Login",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "lastLogout",
      label: "Last Logout",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // padding: "5px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins"
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              // textAlign: "center",
              fontFamily: "Poppins"
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      <Typography variant="h3" className="tp-text-header-primary">
        User Management
      </Typography>
      <Typography
        sx={{
          color: "#607274",
        }}
      >
        Monitor, add, edit, and deactivate users
      </Typography>
      <hr />
      <br />
      <br />
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={["Claims Admin","Admin"].includes(globalData?.role) &&
                <Button
                  className="tp-custom-button-primary "
                  size="small"
                  variant="contained"
                  startIcon={<PersonAddIcon />}
                  onClick={() => navigate("add-user")}
                >
                  Add user
                </Button>
              }
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
