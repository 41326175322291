// components/Checklist.tsx

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
  Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentIcon from '@mui/icons-material/Comment';
// import { IFieldCheckList, IManualCheckList, ITempCheckListComment } from '../../types/FieldCheckList';
import { IRequiredDocStatus } from '../../types/RequiredDocStatus';
import axios from 'axios';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';

interface RequiredDocStatusProps {
  transId: string | undefined;
  requiredDocStatus: IRequiredDocStatus;
  setRequiredDocStatus: (requiredDocStatus: IRequiredDocStatus) => void;
  fetchRequiredDocStatus: () => void;
}

export default function RequiredDocStatus({ transId, requiredDocStatus, setRequiredDocStatus, fetchRequiredDocStatus }: RequiredDocStatusProps) {
  const [comment, setComment] = React.useState(requiredDocStatus.comment);
  const { globalData } = React.useContext(GlobalDataContext);

  const handleChangeApproval = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    const updatedStatus: IRequiredDocStatus = {
      ...requiredDocStatus,
      approve: checked,
    };

    setRequiredDocStatus(updatedStatus);

    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/required-documents/override?transId=${transId}`,
      updatedStatus,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    ).then((res: any) => {

    }).catch((err: any) => {

    });
  };


  const handleChangeOverride = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    const updatedStatus: IRequiredDocStatus = {
      ...requiredDocStatus,
      override: checked,
    };

    setRequiredDocStatus(updatedStatus);

    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/required-documents/override?transId=${transId}`,
      updatedStatus,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    ).then((res: any) => {

      if (checked === false) {
        fetchRequiredDocStatus()
      }
    }).catch((err: any) => {

    });
  };

  const handleComment = async () => {
    const updatedStatus: IRequiredDocStatus = {
      ...requiredDocStatus,
      comment: comment,
    };

    setRequiredDocStatus(updatedStatus);

    await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/required-documents/override?transId=${transId}`,
      updatedStatus,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    ).then((res: any) => {

    }).catch((err: any) => {

    });
  }

  return (
    <div className="tp-mb-20px">
      <Accordion elevation={1} sx={{ mb: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography
                variant="subtitle1"
              >
                Required Documents Status:
              </Typography>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={requiredDocStatus.approve}
                      onChange={(e) => handleChangeApproval(e)}
                      name="approve"
                      color="error"
                      disabled={(requiredDocStatus.override === false ? true : false) || ["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec", "Call Center"].includes(globalData?.role)}
                    />
                  }
                  label={<Chip label={requiredDocStatus.approve ? "Complete" : "Not Complete"} size="small" color={requiredDocStatus.approve ? "error" : "default"} />}
                />
              </Box>

            </Stack>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={requiredDocStatus.override}
                    onChange={(e) => handleChangeOverride(e)}
                    name="override"
                    color="error"
                    disabled={requiredDocStatus.comment === null || requiredDocStatus.comment.length === 0 || ["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec", "Call Center"].includes(globalData?.role)}
                  />
                }
                label={'Override'}
              />
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Prevent accordion toggle
                  // Toggle the accordion manually if needed
                }}
                aria-label="comments"
              >
                <CommentIcon color={requiredDocStatus.comment ? 'primary' : 'action'} />
              </IconButton>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <TextField
              label={'Update Comment'}
              variant="outlined"
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              multiline
              rows={3}
              disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec", "Call Center"].includes(globalData?.role)}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleComment}
              disabled={comment === null || comment.length === 0 || ["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec", "Call Center"].includes(globalData?.role)}
            >
              {'Update Comment'}
            </Button>
            {requiredDocStatus.comment && (
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Saved Comment:
                </Typography>
                <Typography variant="body2" style={{ color: '#9A8311' }}>
                  {requiredDocStatus.comment}
                </Typography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
