import {
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
// import { getSfRepairShops } from "../services/SalesforceApi";
// import { ISfRepairShop } from "../types/SfRepairShop";
import axios from "axios";
import { IRepairShopSf } from "../types/RepairShopSf";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

const formatNumber = (num: number | null): string => {
  if (num === null || num === undefined) return "";
  return num.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};



const BoldTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    fontWeight: "bold",
  },
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
}));

interface ComputationSheetProps {
  laborAndMaterials: number;
  regularParts: number;
  deductible: number;
  totalLossReserve: number;
  totalLabor: number;
  depreciation: number;
  deDetails: any;
  deId?: string;
}

const ComputationSheet: React.FC<ComputationSheetProps> = ({
  laborAndMaterials,
  regularParts,
  deductible,
  depreciation,
  totalLossReserve,
  totalLabor,
  deDetails,
  deId
}) => {
  const [markup, setMarkup] = useState<number>(0.25);
  const [selectedShop, setSelectedShop] = useState<string>("Not Listed");
  const [data, setData] = useState<any[]>([]);
  const [shopList, setShopList] = useState<IRepairShopSf[]>([
    { id: null, name: "Not Listed", sfId: "" },
  ]);

  const { globalData } = React.useContext(GlobalDataContext);

 

  // const shopList = [
  //   "Not Listed",
  //   "1DYNAMIX AUTOMOTIVE INC.",
  //   "ARK DIVERSIFIED AND AUTOMOTORS GROUP, INC.",
  //   "AVESCOR MOTORS OF BACOLOD, INC.",
  //   "AVESCOR MOTORS, INC.",
  //   "AUTOALLEE GROUP INC.",
  //   "AUTOVELOCITY CORPORATION",
  //   "BRITANNICA UNITED MOTORS INCORPORATED",
  //   "BEST SOUTHERN GENESIS MOTORS",
  //   "DAVAO BONIFACIO MOTORS, INC.",
  //   "DIAMOND MOTOR CORPORATION",
  //   "EUROCORSA MOTORCYCLE SHOP",
  //   "FREEWAY MOTOR SALES OF CABANATUAN CORP.",
  //   "FRONTIER AUTOMOTIVE MARILAO CORPORATION",
  //   "GENCARS BATANGAS CITY, INC.",
  //   "GENESIS MOTORS CORPORATION",
  //   "GRAND CANYON MULTI HOLDINGS, INC.",
  //   "GRANDCARS, INC.",
  //   "HONDA CARS DAVAO, INC",
  //   "HONDA CARS ILOCOS, INC.",
  //   "HONDA CARS PANGASINAN, INC.",
  //   "HONDA CARS TARLAC, INC.",
  //   "HYUNDAI E. RODRIGUEZ SR. AVE",
  //   "Hyundai Manila Bay",
  //   "INDUSTRIAL & TRANSPORT EQUIPMENT, INC.",
  //   "ISUZU MAKATI",
  //   "MAXIMOTOR CORP.",
  //   "MOTORCENTRUM CORPORATION",
  //   "MOTORPLAZA INC.",
  //   "MNV AUTO GROUP INC.",
  //   "NORTH QUADRANT VENTURES AND RESOURCES, INC.",
  //   "NORTHPOINT ALLIANCE MOTORS CORP.",
  //   "SAKURA AUTOWORLD, INC",
  //   "SOUTHERN AUTO MART & TRADING CORP.",
  //   "SUZUKI E.RODRIGUEZ SR. AVE.",
  //   "TRI-LINE CAR CARE CENTER",
  //   "Wheels, Inc."
  // ];

  const handleMarkupChange = (event: SelectChangeEvent<number>) => {
    setMarkup(Number(event.target.value));
  };

  const handleShopChange = async (event: SelectChangeEvent<string>) => {
    setSelectedShop(event.target.value);

    try {

      const repairShopObj = shopList.find((item) => item.name === event.target.value)

      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/repair-shop/${deId}`,
        {
          repairShopId: repairShopObj?.sfId,
          repairShop: repairShopObj?.name === "Not Listed" ? null : repairShopObj?.name
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      // console.log('Shop updated successfully');
    } catch (error) {
      console.error('Failed to update the shop', error);

      setSelectedShop((prevShop) => prevShop);
    }
  };

  useEffect(() => {
    const initialData = [
      { category: 'Labor And Materials', values: [laborAndMaterials, laborAndMaterials, null, null, null], isBold: true },
      { category: 'Regular Parts', values: [regularParts, regularParts, null, null, null], isBold: true },
      { category: 'GROSS CLAIM', values: [null, null, null, null, null], isBold: true },
      { category: "LESS: Claimant's Participation", values: [null, null, null, null, null] },
      { category: 'Total Deductible(s)', values: [deductible, deductible, deductible, null, null] },
      { category: 'Depreciation (Regular)', values: [depreciation, depreciation, depreciation, null, null] },
      { category: 'Total Participation Fee', values: [depreciation + deductible, depreciation + deductible, depreciation + deductible, null, null] },
      { category: 'NET CLAIM', values: [null, null, null, null, null], isBold: true },
      { category: 'BOOKED LOSS RESERVES', values: [null, null, null, null, null] },

      { category: 'Labor and Materials', hidden: 'Labor', values: [totalLabor, totalLabor, null, null, null] },
      { category: 'Total Parts', values: [null, null, null, null, null] },
      { category: 'Total 12.00% VAT Due', values: [totalLossReserve * 0.12, totalLossReserve * 0.12, null, null, null] },
      { category: 'Total 2.00% Withholding Tax (Labor & Materials)', values: [totalLabor * .02, -270.24, null, null, null] },
      { category: 'Total 1.00% Withholding Tax (Parts)', values: [714.29, 714.29, null, null, null] },
      { category: 'NET CLAIM PAYMENT/S', values: [99103.54, 94149.09, null, null, null] },
    ];
    // console.log('deductible', deductible);
    // console.log('depre', depreciation);

    let netClaim = 0;
    let bookReserveMarkup = 0;
    let bookReserve = 0;
    let labor_with_vat = 0;
    let totalnetClaim = 0;
    let wht_labor = 0;
    let wht_parts = 0;
    let laborMarkup = 0;
    let totalPartsMarkup = 0;
    let netClaimMarkup = 0;
    let withHoldingPartsMarkup = 0;

    const newData = initialData.map((row) => {
      if (
        row.category === "Labor And Materials" ||
        row.category === "Non-VATable Labor"
      ) {
        row.values[1] = row.values[0]
          ? row.values[0] - row.values[0] * markup
          : row.values[1];
        row.values[2] = row.values[1];
      }

      if (row.category === "Total 12.00% VAT Due") {
        row.values[0] = bookReserve * 0.12;
        row.values[1] = (laborMarkup + totalPartsMarkup) * 0.12;
        labor_with_vat = row.values[0];
      }

      if (row.hidden === "Labor") {
        row.values[1] = row.values[0]
          ? row.values[0] - row.values[0] * markup
          : row.values[1];
        laborMarkup = row.values[1] ?? 0;
      }

      if (row.category === "VATable Labor") {
        row.values[1] = initialData[0]?.values[1];
        row.values[2] = row.values[1];
      }

      // if (row.category === 'Total Participation Fee') {
      //   row.values[1] = initialData[0]?.values[1];
      //   row.values[2] = row.values[1];
      // }

      if (row.category === 'Regular Parts' || row.category === 'Non-VATable Parts') {
        row.values[1] = row.values[0];
        row.values[2] = row.values[1];
      }
      if (row.category === "GROSS CLAIM") {
        row.values[0] =
          (initialData[0]?.values[0] ?? 0) + (initialData[1]?.values[0] ?? 0);
        row.values[1] =
          (initialData[0]?.values[1] ?? 0) + (initialData[1]?.values[1] ?? 0);
        row.values[2] = row.values[1];
      }
      if (row.category === "NET CLAIM") {
        row.values[0] =
          (initialData[2]?.values[0] ?? 0) -
          (initialData[4]?.values[0] ?? 0) -
          depreciation;
        row.values[1] =
          (initialData[2]?.values[1] ?? 0) -
          (initialData[4]?.values[1] ?? 0) -
          depreciation;
        totalnetClaim = row.values[0];
        netClaimMarkup = row.values[1];
        row.values[2] = row.values[1];
      }
      if (row.category === "BOOKED LOSS RESERVES") {
        row.values[0] =
          ((initialData[2]?.values[0] ?? 0) -
            (initialData[4]?.values[0] ?? 0)) /
          1.12;
        row.values[1] =
          ((initialData[2]?.values[1] ?? 0) -
            (initialData[4]?.values[1] ?? 0)) /
          1.12;
        bookReserve = row.values[0];
        bookReserveMarkup = row.values[1];
        row.values[2] = row.values[1];
      }
      if (row.category === "Total Parts") {
        netClaim =
          ((initialData[1]?.values[0] ?? 0) -
            (initialData[4]?.values[0] ?? 0) -
            depreciation) /
          1.12;
        row.values[0] = netClaim;
        row.values[1] = netClaim;
        totalPartsMarkup = row.values[0];
      }
     
      if (row.category === "Total 1.00% Withholding Tax (Parts)") {
        if (selectedShop !== "Not Listed") {

          row.values[0] = netClaim * 0.01;
        
          wht_parts = row.values[0];
        } else {
          row.values[0] = 0;
          wht_parts = 0;
        }
        row.values[1] = row.values[0];
        withHoldingPartsMarkup = row.values[1];
      }

      if (row.category === "Total 2.00% Withholding Tax (Labor & Materials)") {
        row.values[0] = ((initialData[0]?.values[0] ?? 0) / 1.12) * 0.02;
        wht_labor = row.values[0];
        row.values[1] = row.values[0] - row.values[0] * markup;
      }

      if (row.category === "NET CLAIM PAYMENT/S") {
        row.values[0] = totalnetClaim - wht_labor - wht_parts;
        row.values[1] = netClaimMarkup - wht_labor - wht_parts;
      }

      return row;
    });
    setData(newData);
  }, [
    laborAndMaterials,
    regularParts,
    deductible,
    markup,
    depreciation,
    totalLossReserve,
    totalLabor,
    selectedShop,
  ]);

  useEffect(() => {
    const fetchSfRepaiShop = async () => {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/repair-shops-sf`,
        {headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }}
      )
        .then((res: any) => {

          const initRepairShops = [
            { id: null, name: "Not Listed", sfId: `${process.env.REACT_APP_DEFAULT_REPAIR_SHOP}` },
            ...res.data.data
          ]

          setShopList(initRepairShops);
          setSelectedShop(deDetails?.repairShop || "Not Listed")
        })
        .catch((err: any) => console.log(err))
    };

    fetchSfRepaiShop();
  }, [deDetails]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={1} md={1} />
        <Grid item xs={12} sm={4} md={4}>
          <Typography variant="h6" gutterBottom textAlign={"center"}>
            Markup
          </Typography>
          <Select
            value={markup || .25}
            onChange={handleMarkupChange}
            variant="outlined"
            inputProps={{ style: { textAlign: "center" } }}
            MenuProps={{
              PaperProps: {
                style: {
                  textAlign: "center",
                },
              },
            }}
            fullWidth
          >
            <MenuItem value={0}>0%</MenuItem>
            <MenuItem value={0.15}>15%</MenuItem>
            <MenuItem value={0.2}>20%</MenuItem>
            <MenuItem value={0.25}>25%</MenuItem>
            <MenuItem value={0.3}>30%</MenuItem>
            <MenuItem value={0.35}>35%</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h6" gutterBottom textAlign={"center"}>
            Shop List 1% WHT Parts
          </Typography>
          <Select
            value={selectedShop}
            onChange={handleShopChange}
            variant="outlined"
            fullWidth
            inputProps={{ style: { textAlign: "center" } }}
            MenuProps={{
              PaperProps: {
                style: {
                  textAlign: "center",
                },
              },
            }}
          >
            {shopList.map((shop, index) => (
              <MenuItem key={index} value={shop.name}>
                {shop.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={1} md={1} />
      </Grid>
      <br /><br />
      <div style={{ padding: "20px" }}>
        <TableContainer component={Paper} >
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell></TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
                <TableHeaderCell sx={{ textAlign: 'right' }}>Offer 1</TableHeaderCell>
                <TableHeaderCell sx={{ textAlign: 'right' }}>Offer 2</TableHeaderCell>
                <TableHeaderCell sx={{ textAlign: 'right' }}>Cash Settlement</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ textAlign: 'right' }}>
              {data.map((row, index) => (
                <React.Fragment key={index}>
                  {row.isBold ? (
                    <BoldTableRow>
                      <TableCell>{row.category}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        {row.category === 'Labor And Materials' ||
                          row.category === 'Regular Parts' ||
                          row.category === 'Total Deductible(s)'
                          ? `₱ ${formatNumber(row.values[0])}`
                          : ''}
                      </TableCell>
                      {row.values.map((value: any, idx: any) => (
                        <TableCell key={idx} sx={{ textAlign: 'right' }}>
                          {value !== null ? `₱ ${formatNumber(value)}` : ''}
                        </TableCell>
                      ))}
                    </BoldTableRow>
                  ) : (
                    <TableRow>
                      <TableCell>{row.category}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        {row.category === 'Labor And Materials' ||
                          row.category === 'Regular Parts' ||
                          row.category === 'Total Deductible(s)'
                          ? `₱ ${formatNumber(row.values[0])}`
                          : ''}
                      </TableCell>
                      {row.values.map((value: any, idx: any) => (
                        <TableCell key={idx} sx={{ textAlign: 'right' }}>
                          {value !== null ? `₱ ${formatNumber(value)}` : ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ComputationSheet;
