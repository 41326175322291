import {
  DialogContent,
  Grid,
  Typography
} from "@mui/material";
import * as React from "react";
import AffidavitForm from './AffidavitForm';
import CRForm from './CRForm';
import DocumentSummary from './DocumentSummary';
import DriverLicenseForm from './DriverLicenseForm';
import EstimationForm from './EstimationForm';
import MotorVehicleForm from './MotorVehicleForm';
import ORForm from './ORForm';
import OtherForm from './OtherForm';
import PoliceReportForm from './PoliceReportForm';
// import './general.scss';
import LogoImage from '../../assets/logo.json';
import ClientDetails from "./ClientDetails";
import DriverLicenseORForm from "./DriverLicenseORForm";
import PolicyDetails from "./PolicyDetails";


function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

type OptionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export default function MainModal({
  data,
  attachments,
  transID,
  scroll,
  selectedForm,
  userName,
  disabled,
  setIsDisabled,
  refereshList,
  close,
  docId,
}: any) {
  const [option, setOption] = React.useState<OptionType>(selectedForm);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false
  })

  const handleNext = () => {
    setOption(option === 7 ? 0 : (option + 1) as OptionType);
  };

  const handleBack = () => {
    setOption((option - 1) as OptionType);
  };


  //#endregion Control Methods
  React.useEffect(() => {

    setIsDisabled(disabled)

  }, [])

  const modalRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <>
      <DialogContent ref={modalRef} dividers={scroll === 'paper'}>
        <div style={{ margin: "40px" }}>
          <Grid container spacing={2}>
            <Grid item md={9}>

              {/* Official Receipt */}
              {option === 0 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Official Receipt Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Certification of Registration */}
              {option === 1 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Certicate of Registration Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Driver's License */}
              {option === 2 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Driver's License Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Police Report */}
              {option === 3 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Motor Vehicle Form Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Motor Vehicle */}
              {option === 4 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Police Report Validation
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {/* Damage Evaluation and Estimation */}
              {option === 5 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Shop Estimate Form
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}
              {option === 6 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Affidavit
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {option === 7 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Other Documents
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

              {option === 8 && <div>
                <Typography variant="h4" className="tp-text-header-primary">
                  Driver License Official Receipt
                </Typography>
                <Typography variant="body1">
                  Note: Please check all Required Fields with (<span className="tp-text-required">*</span>) before you submit.
                </Typography>
              </div>}

            </Grid>
            <Grid item md={3} textAlign={"center"}>
              <img src={LogoImage.logo} width={180} height={60} alt='img' />
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" /><br /><br />
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={8} sm={8} md={7} >
              <Typography variant="h6" className="tp-text-header-primary">
                Transaction No.: &ensp;
                <span className="tp-text-default">
                  ACAP-{data?.transId}
                </span>
              </Typography>
              <Typography variant="h6" className="tp-text-header-primary tp-mb-30px">
                Document ID.: &ensp;
                <span className="tp-text-default">
                  {docId}
                </span>
              </Typography>

              {option === 0 && <>
                <ORForm
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                  modalRef={modalRef}
                />
              </>}

              {option === 1 && <>
                <CRForm
                  option={option}
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                  modalRef={modalRef}
                />
              </>}

              {option === 2 && <>
                <DriverLicenseForm
                  option={option}
                  setOption={setOption}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                  modalRef={modalRef}
                />
              </>}

              {option === 3 && <>
                <MotorVehicleForm
                  option={option}
                  setOption={setOption}
                  // policyNoField={policyNoField}
                  // onChangePolicy={onChangePolicy}
                  transID={transID}
                  disabled={disabled}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  close={close}
                  userData={userName}
                  docId={docId}
                  modalRef={modalRef}
                />
              </>}

              {option === 4 &&
                <>
                  <PoliceReportForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                    modalRef={modalRef}
                  />
                </>}

              {option === 5 &&
                <>
                  <EstimationForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                    modalRef={modalRef}
                  />
                </>}

              {option === 6 &&
                <>
                  <AffidavitForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                    modalRef={modalRef}
                  />
                </>}
              {option === 7 &&
                <>
                  <OtherForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                    modalRef={modalRef}
                  />
                </>}
              {option === 8 &&
                <>
                  <DriverLicenseORForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                    modalRef={modalRef}
                  />
                </>}
            </Grid>

            <Grid item xs={4} sm={4} md={5} >
              <Typography variant="h6" className="tp-text-header-primary tp-mb-30px">
                Document Summary
              </Typography>
              <DocumentSummary
                refereshList={(e: any) => refereshList(e)}
                transID={transID}
                data={data}
                attachments={attachments}
                option={option}
                docId={docId}
              />
              {/* 
                // #region Policy Data
              */}
              <Typography variant="h6" className="tp-text-header-primary tp-mt-30px tp-mb-30px">
                Policy Details
              </Typography>
              <PolicyDetails transId={transID} />
              {/* 
                // #region Client Data
              */}
              <Typography variant="h6" className="tp-text-header-primary tp-mt-30px tp-mb-30px">
                Client Details
              </Typography>
              <ClientDetails transId={transID} />
            </Grid>
          </Grid>
        </div>
      </DialogContent>

    </>
  );
}
