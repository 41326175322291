// components/Checklist.tsx

import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { IFieldCheckList, IManualCheckList, ITempCheckListComment } from '../../types/FieldCheckList';
import { IAcapTask } from '../../types/AcapTask';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import { IClaimHistory } from '../../types/ClaimHistory';
import axios from 'axios';

interface ICheckListProps {
  manualCheckList: IManualCheckList;
  setManualCheckList: (manualCheckList: any) => void;
  updateFieldCheckList: (id: string, newValue: number) => void;
  setFieldCheckListComment: (id: string, comment: string) => void;
  checklistComments: ITempCheckListComment[];
  updateFieldCheckListComments: () => void;
  acapTask: IAcapTask;
  claimHistory: IClaimHistory[];
  handleOverride: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

export default function Checklist({
  manualCheckList,
  setManualCheckList,
  updateFieldCheckList,
  setFieldCheckListComment,
  checklistComments,
  updateFieldCheckListComments,
  acapTask,
  claimHistory,
  handleOverride
}: ICheckListProps) {
  const { globalData } = React.useContext(GlobalDataContext);
  // Local state to manage comment inputs
  // const [commentInputs, setCommentInputs] = useState<{ [key: string]: string }>({});

  // useEffect(() => {
  //   // Initialize commentInputs with existing comments
  //   const initialComments: { [key: string]: string } = {};
  //   manualCheckList.fieldCheckList.forEach((item) => {
  //     initialComments[item.id] = item.comment || '';
  //   });
  //   // setCommentInputs(initialComments);
  // }, [manualCheckList.fieldCheckList]);

  const getCommentById = (id: string): string | undefined => {
    const item = checklistComments.find(comment => comment.id === id);
    return item ? item.comment : undefined;
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const newValue = checked ? 1 : 0;
    updateFieldCheckList(id, newValue);
  };

  const handleCommentChange = (id: string, comment: string) => {
    setFieldCheckListComment(id, comment);
  };

  // const handleSaveComment = (id: string) => {
  //   const comment = commentInputs[id] || '';
  //   const updatedCheckList = manualCheckList.fieldCheckList.map((item) =>
  //     item.id === id ? { ...item, comment } : item
  //   );
  //   setManualCheckList({ ...manualCheckList, fieldCheckList: updatedCheckList });
  // };

  // Calculate the totals for "Yes" and "No"
  function countChecklistItems(checklist: IFieldCheckList[]) {
    let yesCount = 0;
    let noCount = 0;

    checklist.forEach((item) => {
      if (item.value === 1) {
        yesCount++;
      } else if (item.value === 0) {
        noCount++;
      }
    });

    return { yesCount, noCount };
  }

  const { yesCount, noCount } = countChecklistItems(manualCheckList.fieldCheckList);

  return (
    <div className="tp-mb-20px">
      <Grid container spacing={2} className="tp-mtb-10px">
        <Grid item md={10}>
          <Typography variant="h6" className="tp-text-header-primary">
            Fraud Indicator Checklist (Manual Checklist)
          </Typography>
        </Grid>
        <Grid item md={2} textAlign="center">
          <Typography variant="h6" className="tp-text-header-primary">
            No / Yes
          </Typography>
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary tp-mb-20px" />

      {manualCheckList.fieldCheckList.map((item, index) => (
        <Accordion key={item.id} elevation={1}
          sx={{
            mb: 1
          }}>
          <AccordionSummary
            expandIcon={<EditIcon color={item.comment ? 'primary' : 'disabled'} />}
            aria-controls={`panel-${item.id}-content`}
            id={`panel-${item.id}-header`}
            sx={{
              backgroundColor: item.id === "fraud_12" && claimHistory && claimHistory.length > 1 ? "rgb(177, 17, 22)" : "white",
              color: item.id === "fraud_12" && claimHistory && claimHistory.length > 1 ? "white" : "black",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <div>
                <Typography variant="body1">{item.description}
                  {item.comment &&
                    <Typography variant="body2">
                      Comment:&ensp;<span style={{ color: "#303ca3" }}>{item.comment}</span>
                    </Typography>
                  }
                </Typography>
              </div>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      id={item.id}
                      checked={item.value === 1}
                      onChange={handleToggle}
                      name={item.description}
                      color='default'
                      disabled={item.id === "fraud_12" && manualCheckList.override_12 !== true}
                    />
                  }
                  label={item.value === 1 ? 'Yes' : 'No'}
                />
                {item.id === "fraud_12" && (
                  <FormControlLabel
                  control={
                    <Switch
                      id={`override_12`}
                      checked={manualCheckList.override_12}
                      onChange={handleOverride}
                      color='default'
                      name={`override_12`}
                    />
                  }
                  label={'Override'}
                />
                )}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent accordion toggle
                    // Toggle the accordion manually if needed
                  }}
                  aria-label="comments"
                >
                  <CommentIcon color={item.comment ? 'primary' : 'action'} />
                </IconButton>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <TextField
                label={item.comment ? 'Update Comment' : 'Add Comment'}
                variant="outlined"
                fullWidth
                value={getCommentById(item.id)}
                onChange={(e) => handleCommentChange(item.id, e.target.value)}
                multiline
                rows={3}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={updateFieldCheckListComments}
                disabled={[
                  "Clec",
                  "Jr Clec",
                  "Head Motor Claims",
                  "Head Claims",
                  "Head Clec",
                  "Call Center"
                ].includes(globalData?.role)}
              >
                {item.comment ? 'Update Comment' : 'Add Comment'}
              </Button>
              {/* {item.comment && (
                <Box mt={2}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Saved Comment:
                  </Typography>
                  <Typography variant="body2" style={{ color: '#9A8311' }}>
                    {item.comment}
                  </Typography>
                </Box>
              )} */}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Display the totals */}
      <Typography variant="h6" align='center' color="primary"> Total:&ensp;No - {noCount} / Yes - {yesCount}</Typography>
    </div>
  );
}
