import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { formatDateEmail } from '../api/FormatDateEmail';
import { GlobalDataContext } from "../contexts/GlobalDataContext";


interface EmailRequest {
  transId: number;
  perilType: string;
  sender: string;
  documents: string[];
  to: string[]; // New field for 'To' addresses
  cc: string[]; // New field for 'CC' addresses
}


interface EmailMissingProps {
  transId: any;
  sender: string;
}

interface EmailHistoryEntry {
  id: number;
  trans_id: number;
  peril_type: string;
  documents: string;
  to_addresses: string; // Updated field
  cc_addresses: string; // Updated field
  sent_date: string | null;
}

const perilTypes: Record<string, string[]> = {
  'Own Damage': [
    'Police Report/ Accident Report Form/ Notarized Affidavit',
    "Photocopy of Driver's License",
    "Photocopy of Driver's License Current Official Receipt",
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Pictures of the scene of accident, if available',
    'Estimate from preferred shop, if available',
    'Unit inspection',
  ],
  'Carnap Claim': [
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Police Report',
    'Alarm Sheet',
    'Complaint Sheet',
    'Certificate of Non-Recovery',
  ],
  'Third Party- Property Damage involving another vehicle': [
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Certificate of No Claim (CNC) from the Third Party Insurer',
    'Pictures of damaged unit, if available',
    'Unit inspection',
  ],
  'Third Party- Property Damage other than to a Vehicle': [
    'Proof of ownership of damaged property',
    'Colored photos of damaged property',
    'Repair estimate/ quotation from at least two (2) contractors, engineers, architects &/or suppliers',
  ],
  'Unnamed Passenger Personal Accident (UPPA)/ Bodily Injury': [
    'Police Report/ Accident Report Form/ Notarized Affidavit',
    "Photocopy of Driver's License",
    "Photocopy of Driver's License Current Official Receipt",
    "Photocopy of Vehicle's Certificate of Registration (CR)",
    "Photocopy of Current Official Receipt (OR) of Vehicle's Certificate of Registration (CR)",
    'Medical Certificate',
    'Hospital Statement of Account',
    'Original Medical Receipts/ Hospital Receipts',
  ],
  'Third Party Death Claim for CTPL/VTPL': [
    'Death Certificate',
    'Original Receipt of Funeral/ Burial expenses',
    "Valid ID's of injured party",
  ],
  'UPPA with Death Claim': [
    'Death Certificate',
    'Original Receipt of Funeral/ Burial expenses',
    "Valid ID's of injured party",
  ],
};
const EmailMissing = ({ transId, sender }: EmailMissingProps) => {
  const [open, setOpen] = useState(false);
  const [perilType, setPerilType] = useState<string>('');
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [emailHistory, setEmailHistory] = useState<EmailHistoryEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const { globalData } = React.useContext(GlobalDataContext);

  // New states for 'To' and 'CC'
  const [to, setTo] = useState<string>(sender);
  const [cc, setCc] = useState<string>('');
  // Add new state for errors
  const [toError, setToError] = useState<string>('');
  const [ccError, setCcError] = useState<string>('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // Reset fields when modal is closed
    setPerilType('');
    setSelectedDocuments([]);
    setTo(sender);
    setCc('');
    setToError('');
    setCcError('');
  };

  const handlePerilTypeChange = (event: SelectChangeEvent<string>) => {
    setPerilType(event.target.value);
    setSelectedDocuments([]);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = event.target.name;
    if (event.target.checked) {
      setSelectedDocuments([...selectedDocuments, document]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((doc) => doc !== document));
    }
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTo(value);

    // Validate emails
    const emails = value.split(',').map(email => email.trim()).filter(email => email);
    const invalidEmails = emails.filter(email => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setToError(`Invalid email(s): ${invalidEmails.join(', ')}`);
    } else {
      setToError('');
    }
  };

  const handleCcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCc(value);

    // Validate emails
    const emails = value.split(',').map(email => email.trim()).filter(email => email);
    const invalidEmails = emails.filter(email => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setCcError(`Invalid email(s): ${invalidEmails.join(', ')}`);
    } else {
      setCcError('');
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);

    const toEmails = to.split(',').map(email => email.trim()).filter(email => email);
    const ccEmails = cc.split(',').map(email => email.trim()).filter(email => email);

    const emailRequest: EmailRequest = {
      transId,
      perilType,
      sender,
      documents: selectedDocuments,
      to: toEmails.length > 0 ? toEmails : [sender], // Default to sender if no To addresses
      cc: ccEmails,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/email/send`, emailRequest,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      // Fetch the updated email history
      await fetchEmailHistory();

      // Optionally, show a success message or notification
      alert('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email');
    } finally {
      setLoading(false);
      // Reset fields after sending
      setSelectedDocuments([]);
      setPerilType('');
      setTo(sender);
      setCc('');
      setOpen(false); // Close the modal upon successful send
      setToError('');
      setCcError('');
    }
  };

  const fetchEmailHistory = async () => {
    try {
      const response = await axios.get<EmailHistoryEntry[]>(`${process.env.REACT_APP_BACKEND_URL}/api/email/history/${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      setEmailHistory(response.data);
    } catch (error) {
      console.error('Error fetching email history:', error);
    }
  };

  useEffect(() => {
    fetchEmailHistory();
  }, [transId]);

  return (
    <div>
      <Button variant="contained" color="error" onClick={handleOpen} startIcon={<EmailIcon />} disabled={["Call Center"].includes(globalData?.role)}>
        Send Email for Missing Documents
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "50%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 20, right: 25 }}
          >
            <CloseIcon color="error" fontSize="large" />
          </IconButton>
          <Typography variant="h4" className="tp-text-header-primary">
            Send Missing Documents Email
          </Typography>

          <hr className="tp-border-hr_primary" />

          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="To"
                  variant="outlined"
                  value={to}
                  onChange={handleToChange}
                  helperText="Separate multiple emails with commas"
                  error={!!toError}
                  FormHelperTextProps={{ error: !!toError }}
                />
                {toError && <Typography color="error">{toError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="CC"
                  variant="outlined"
                  value={cc}
                  onChange={handleCcChange}
                  helperText="Separate multiple emails with commas"
                  error={!!ccError}
                  FormHelperTextProps={{ error: !!ccError }}
                />
                {ccError && <Typography color="error">{ccError}</Typography>}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="peril-type-label">Choose Peril Type</InputLabel>
                <Select labelId="peril-type-label" value={perilType} onChange={handlePerilTypeChange} label="Choose Peril Type">
                  {Object.keys(perilTypes).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormGroup>
                {perilTypes[perilType]?.map((document) => (
                  <FormControlLabel
                    key={document}
                    control={<Checkbox checked={selectedDocuments.includes(document)} onChange={handleCheckboxChange} name={document} />}
                    label={document}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Box
                sx={{
                  float: "right",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSendEmail}
                  disabled={
                    selectedDocuments.length === 0 ||
                    loading ||
                    perilType === '' ||
                    toError !== '' ||
                    ccError !== ''
                  }
                >
                  Confirm
                </Button>
                &ensp;
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              {/* <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendEmail}
                  disabled={
                    selectedDocuments.length === 0 ||
                    loading ||
                    perilType === '' ||
                    toError !== '' ||
                    ccError !== ''
                  }
                  startIcon={loading ? <CircularProgress size={24} /> : undefined}
                >
                  {loading ? 'Sending...' : 'Send Email'}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                  Cancel
                </Button>

              </Box> */}
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" textAlign="center">
                Email History
              </Typography>
              <hr style={{ width: "1000px", alignSelf: "center", marginTop: "20px", marginBottom: "10px" }} />
              <Box sx={{ maxHeight: 400, overflowY: 'auto', pr: 1 }}>
                <List>
                  {emailHistory.map((email) => (
                    <ListItem key={email.id} alignItems="flex-start">
                      <ListItemText
                        primary={`Email sent: ${email.sent_date ? formatDateEmail(email.sent_date) : 'No date available'}`}
                        secondary={
                          <>
                            <Typography variant="body2" color="textPrimary">
                              Peril Type: {email.peril_type}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              To: {email.to_addresses}
                            </Typography>
                            {email.cc_addresses && (
                              <Typography variant="body2" color="textSecondary">
                                CC: {email.cc_addresses}
                              </Typography>
                            )}
                            <Typography variant="body2" color="textSecondary">
                              Missing Documents:
                              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                                {email.documents.split(', ').map((doc, idx) => (
                                  <ListItem key={idx} sx={{ display: 'list-item', p: 0 }}>
                                    {doc}
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailMissing;
