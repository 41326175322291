type ChipComponent = {
  label: string;
  size: "small" | "medium";
  variant: "outlined" | "filled";
  color: "default" | "warning" | "success" | "primary";
};

// Status Mapping
const statusMap: Record<number, ChipComponent> = {
  0: { label: "Bot Processing", size: "small", variant: "outlined", color: "default" },
  10: { label: "Manual", size: "small", variant: "outlined", color: "warning" },
  15: { label: "Open", size: "small", variant: "outlined", color: "success" },
  1: { label: "Open", size: "small", variant: "outlined", color: "success" },
  11: { label: "Open", size: "small", variant: "outlined", color: "success" },
  3: { label: "For Damage Evaluation", size: "small", variant: "outlined", color: "success" },
  9: { label: "Pending For Offer", size: "small", variant: "outlined", color: "success" },
  18: { label: "Pending Documents", size: "small", variant: "outlined", color: "success" },
  21: { label: "Pending Inhouse Survey Clec", size: "small", variant: "outlined", color: "success" },
  22: { label: "Pending Inhouse Survey IMSI", size: "small", variant: "outlined", color: "success" },
  19: { label: "Pending Adjuster's Report", size: "small", variant: "outlined", color: "success" },
  31: { label: "Closed Withdrawn", size: "small", variant: "outlined", color: "warning" },
};

export const convertStatus = (status: number) => {
  const component = statusMap[status];
  return component ? { label: component.label, component } : null;
};

// Priority Mapping
const priorityMap: Record<number, ChipComponent> = {
  1: { label: "High", size: "small", variant: "outlined", color: "warning" },
  0: { label: "Low", size: "small", variant: "outlined", color: "primary" },
};


export const convertPriority = (priority: number) => {
  const component = priorityMap[priority];
  return component ? { label: component.label, component } : null;
};

// Channel Mapping
const channelMap: Record<number, ChipComponent> = {
  1: { label: "Email", size: "small", variant: "outlined", color: "primary" },
  2: { label: "Web Form", size: "small", variant: "outlined", color: "primary" },
  3: { label: "From Clec", size: "small", variant: "outlined", color: "primary" },
};

export const convertChannel = (channel: number) => {
  const component = channelMap[channel];
  return component ? { label: component.label, component } : null;
};
