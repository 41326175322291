import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Card,
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { GlobalDataContext } from "../../../contexts/GlobalDataContext";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { v4 as uuidv4, validate } from "uuid";

interface IVictimsTableProps {
  transID: number;
  mainState: any;
  victimsTable: any;
  setVictimsTable: (victimsTable: any) => void;
  disabled: boolean;
}

const VictimsTable = (props: IVictimsTableProps) => {
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [editIndex, setEditIndex] = React.useState<string | null>(null);

  const handleChange = (id: string, field: string, value: string) => {
    const updatedRows = props.victimsTable.rows.map((row: any) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    console.log("Updated rows:", updatedRows);
    props.setVictimsTable({ ...props.victimsTable, rows: updatedRows });
  };

  const handleSave = (id: string) => {
    console.log("Save changes for row:", props.victimsTable);
    setEditIndex(null); // Exit edit mode
  };

  const handleCancel = () => {
    setEditIndex(null); // Exit edit mode
  };

  const handleDelete = (id: string) => {
    // Filter out the row with the matching ID
    const updatedRows = props.victimsTable.rows.filter(
      (row: any) => row.id !== id
    );
    console.log("handleDelete", updatedRows);

    // Update the parent state with the filtered rows
    props.setVictimsTable({ ...props.victimsTable, rows: updatedRows });
  };

  const handleAddItem = () => {
    const newRow = {
      id: uuidv4(),
      "Name": "",
      "Address": "",
      "Age": "",
      "Remarks": "",
    };

    props.setVictimsTable((prev: any) => ({
      ...prev,
      rows: [...prev.rows, newRow],
    }));

    console.log("Add new item:", newRow);

    setEditIndex(newRow.id);
  };

  return (
    <Card sx={{ px: 2, py: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Typography variant="h6" className="tp-text-header-primary">
            List of Victims
            <Typography
              variant="caption"
              display="block"
              className="tp-text-subtitle"
            >
              Note: Please add atleast one item.
            </Typography>
          </Typography>
        </Grid>
        {props.disabled === true ? (
          // <Grid item md={2} alignSelf="center" textAlign="center">
          //     <Button color="warning" size='small' variant='contained' startIcon={<SendIcon />} onClick={handleTransferParts}>
          //         Transfer
          //     </Button>
          // </Grid>
          <></>
        ) : (
          <Grid item md={2} alignSelf="center" textAlign="center">
            <Button
              color="primary"
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddItem}
              disabled={[
                "Clec",
                "Jr Clec",
                "Head Motor Claims",
                "Head Claims",
                "Head Clec",
                "Call Center"
              ].includes(globalData?.role)}
            >
              Add Item
            </Button>
          </Grid>
        )}
      </Grid>
      <br />
      <TableContainer>
        <Table
          sx={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgba(177,17,22,1)" }}>
              <TableCell
                sx={{
                  color: "white",
                  borderLeft: "0.5px solid rgb(214, 213, 213)",
                  borderRight: "0.5px solid rgb(214, 213, 213)" 
                }}
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  borderLeft: "0.5px solid rgb(214, 213, 213)",
                  borderRight: "0.5px solid rgb(214, 213, 213)" 
                }}
              >
                Address
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  borderLeft: "0.5px solid rgb(214, 213, 213)",
                  borderRight: "0.5px solid rgb(214, 213, 213)" 
                }}
              >
                Age
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  borderLeft: "0.5px solid rgb(214, 213, 213)",
                  borderRight: "0.5px solid rgb(214, 213, 213)" 
                }}
              >
                Remarks
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  borderLeft: "0.5px solid rgb(214, 213, 213)",
                  borderRight: "0.5px solid rgb(214, 213, 213)" 
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.victimsTable.rows.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{ textAlign: "center", padding: "16px" }}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            ) : (
              props.victimsTable.rows.map((row: any) => (
                <TableRow key={row.id}>
                  {/* Editable or display cell based on editIndex */}
                  <TableCell sx={{ padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Name"]}
                        onChange={(e) =>
                          handleChange(row.id, "Name", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Name"]
                    )}
                  </TableCell>
                  <TableCell sx={{ 
                      padding: "8px 16px",
                      borderLeft: "0.5px solid rgb(214, 213, 213)",
                      borderRight: "0.5px solid rgb(214, 213, 213)" 
                    }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Address"]}
                        onChange={(e) =>
                          handleChange(row.id, "Address", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Address"]
                    )}
                  </TableCell>
                  <TableCell sx={{ 
                      padding: "8px 16px",
                      borderLeft: "0.5px solid rgb(214, 213, 213)",
                      borderRight: "0.5px solid rgb(214, 213, 213)" 
                    }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Age"]}
                        onChange={(e) =>
                          handleChange(row.id, "Age", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Age"]
                    )}
                  </TableCell>
                  <TableCell sx={{ 
                      padding: "8px 16px",
                      borderLeft: "0.5px solid rgb(214, 213, 213)",
                      borderRight: "0.5px solid rgb(214, 213, 213)" 
                    }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Remarks"]}
                        onChange={(e) =>
                          handleChange(row.id, "Remarks", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Remarks"]
                    )}
                  </TableCell>
                  <TableCell sx={{ 
                      padding: "8px 16px",
                      borderLeft: "0.5px solid rgb(214, 213, 213)",
                      borderRight: "0.5px solid rgb(214, 213, 213)" 
                    }}>
                    {editIndex === row.id ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => handleSave(row.id)}
                          title="Save"
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleCancel()}
                          title="Cancel"
                        >
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => setEditIndex(row.id)}
                          title="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(row.id)}
                          title="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default VictimsTable;
