import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
//import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, FormControl, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
// import InnerImageZoom from 'react-inner-image-zoom';
// import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import axios from 'axios';
import { formatDate } from '../../api/FormatDate';
import NoImageFound from '../../assets/noFoundImage.json';
import DocumentViewer from './DocumentViewer';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import Select from 'react-select'

// Ace Balce
type OptionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

//#region Other Methods
const optionToDocumentType: { [key in OptionType]: string } = {
  0: 'Official Receipt',
  1: 'Certificate of Registration',
  2: 'Driver License',
  3: 'Motor Vehicle',
  4: 'Police Report',
  5: 'Shop Estimate',
  6: 'Affidavit',
  7: 'Other Documents',
  8: 'Driver License OR',
};

interface DocumentSummaryProps {
  data: any;
  attachments: any[];
  option: OptionType;
  docId: number;
  transID: any;
  refereshList: any;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const DocumentSummary: React.FC<DocumentSummaryProps> = ({
  attachments,
  option,
  docId,
  transID,
  refereshList
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [formData, setFormData] = React.useState<any>({ id: 0, fileName: '', tag: '' });
  const [data, setData] = React.useState<any>({ id: 0, fileName: '', tag: '' });
  const [isEditing, setIsEditing] = React.useState<any>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);

  const handleTagsChange = (selectedOption: {
    value: string;
    label: string;
  } | null) => {
    setFormData((prev: any) => ({ ...prev, tag: selectedOption ? selectedOption.value : "" }));
  };

  const handleFileNameChange = (e: any) => {
    const { value } = e.target;
    setFormData({ ...formData, fileName: value });
  };

  // Edit Details
  const editDetails = (e: any) => {
    setFormData(e);
    setIsEditing(true);
  };

  // Save Details
  const saveDetails = () => {
    setData(data.map((attachment: any) => (attachment.id === formData.id ? formData : attachment)));
    setFormData({ id: null, tag: '', fileName: '' });
    setIsEditing(false);
  };

  useEffect(() => {
    const documentType = optionToDocumentType[option];
    const initialIndex = attachments.findIndex(
      (attachment: any) => attachment.documentType === documentType
    );
    setSelectedIndex(initialIndex !== -1 ? initialIndex : null);
  }, [option, attachments]);

  const handleChange = (index: number) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  };

  const patchTagFileName = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/patch?id=${formData.id}`,
        {
          "tag": formData.tag,
          "fileName": formData.fileName
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        refereshList(
          `/api/acap/document-validation/document-tracker?transId=${transID}`
        );
        setIsEditing(false)
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const documentType = optionToDocumentType[option];
  const filteredAttachments = attachments?.filter(
    (attachment: any) => attachment?.documentType === documentType && attachment?.docId === docId
  );

  const tagOptions = [
    { value: 'insured', label: 'insured' },
    { value: 'tp1', label: 'tp1' },
    { value: 'tp2', label: 'tp2' },
    { value: 'tp3', label: 'tp3' },
    { value: 'tp4', label: 'tp4' },
    { value: 'tp5', label: 'tp5' },
    { value: 'cp1', label: 'cp1' },
    { value: 'cp2', label: 'cp2' },
    { value: 'cp3', label: 'cp3' },
    { value: 'cp4', label: 'cp4' },
    { value: 'cp5', label: 'cp5' },
    { value: 'p1', label: 'p1' },
    { value: 'p2', label: 'p2' },
    { value: 'p3', label: 'p3' },
    { value: 'p4', label: 'p4' },
    { value: 'p5', label: 'p5' },
  ]

  return (
    <div>
      {filteredAttachments && filteredAttachments.length > 0 ? (
        filteredAttachments.map((res: any, index: number) => (
          <Accordion
            key={index}
            expanded={selectedIndex === index}
            onChange={() => handleChange(index)}
            defaultExpanded={selectedIndex === index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={'panel' + res.id + 'a-content'}
              id={'panel' + res.id + 'a-header'}
              className={
                res.status === 'Missing'
                  ? 'tp-bg-disable'
                  : 'tp-bg-lightPrimary'
              }
            >
              <Typography className="tp-text-bold">{res.documentType}</Typography>
            </AccordionSummary>
            {res.status === 'Missing' ? null : (
              <AccordionDetails>
                <Typography variant="body1" className="tp-mt-15px">
                  File URL: &ensp;
                  <span>
                    <a
                      href={res.fileUrl}
                      target="_blank"
                      className='tp-custom-link-primary'
                      rel="noopener noreferrer"
                    >
                      Document Link
                    </a>
                  </span>
                </Typography>
                <Typography variant="body1" className="tp-mb-15px">
                  Date Received: &ensp;
                  <span className="tp-text-primary">
                    {formatDate(res.dateReceived)}
                  </span>
                </Typography>

                <Box sx={{ display: 'flex', gap: 2, paddingTop: '15px' }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      saveDetails();
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 4 }}>
                      <FormControl size="small">
                        {/* <InputLabel id="demo-simple-select-label">Tag</InputLabel> */}
                        {/* <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={!isEditing}
                          value={isEditing ? formData.tag : res.tag}
                          onChange={handleTagsChange}
                          size="small"
                          label="Tag"
                          displayEmpty
                          style={{ minWidth: 140 }}
                          open={true}
                        >
                          <MenuItem value="insured">insured</MenuItem>
                          <MenuItem value="tp1">tp1</MenuItem>
                          <MenuItem value="tp2">tp2</MenuItem>
                          <MenuItem value="tp3">tp3</MenuItem>
                          <MenuItem value="tp4">tp4</MenuItem>
                          <MenuItem value="tp5">tp5</MenuItem>
                          <MenuItem value="cp1">cp1</MenuItem>
                          <MenuItem value="cp2">cp2</MenuItem>
                          <MenuItem value="cp3">cp3</MenuItem>
                          <MenuItem value="cp4">cp4</MenuItem>
                          <MenuItem value="cp5">cp5</MenuItem>
                          <MenuItem value="p1">p1</MenuItem>
                          <MenuItem value="p2">p2</MenuItem>
                          <MenuItem value="p3">p3</MenuItem>
                          <MenuItem value="p4">p4</MenuItem>
                          <MenuItem value="p5">p5</MenuItem>
                        </Select> */}
                        <Select
                          placeholder="Tag"
                          options={tagOptions}
                          isDisabled={!isEditing}
                          value={tagOptions.find(option => option.value === (isEditing ? formData.tag : res.tag)) || null}
                          onChange={handleTagsChange}
                        />
                      </FormControl>

                      <TextField
                        id="outlined-basic"
                        label="Filename"
                        disabled={!isEditing}
                        variant="outlined"
                        size="small"
                        placeholder="Please enter filename"
                        value={isEditing ? formData.fileName : res.fileName}
                        onChange={(e) => handleFileNameChange(e)}
                      />
                    </Box>
                  </form>
                  {isEditing ?
                    <Box sx={{ display: 'flex', gap: 1 }}>

                      <Button variant="contained"
                        sx={{
                          border: "1px solid",
                          backgroundColor: "#1976d2",
                          color: "#fff",
                          ":hover": {
                            backgroundColor: "#095eb3",
                          },
                        }}
                        onClick={patchTagFileName}>
                        Save
                      </Button>

                      <Button
                        variant="contained"
                        className="tp-custom-button-primary"
                        size="small"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </Button>

                    </Box>
                    :
                    <Button
                      variant="contained"
                      sx={{
                        border: "1px solid",
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        ":hover": {
                          backgroundColor: "#095eb3",
                        },
                      }}
                      onClick={() => editDetails(res)}
                      disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec","Call Center"].includes(globalData?.role)}
                    >
                      Update
                    </Button>}
                </Box>
                <hr />
                <DocumentViewer documentUrl={res.fileUrl} />
              </AccordionDetails>
            )}
          </Accordion>
        ))
      ) : (
        <>
          <img src={NoImageFound.image} width="100%" height="75%" alt="img" />
          <br />
          <Typography variant="h6" textAlign="center">
            No Available Document
          </Typography>
        </>
      )}
    </div>
  );
};


export default DocumentSummary;