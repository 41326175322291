import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import BusinessRulesTask from "../components/document_validation/checklist/BusinessRulesTask";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

export default function BusinessRulePage() {
  const { globalData } = React.useContext(GlobalDataContext);
  const [claimNo, setClaimNo] = React.useState<string>("");
  const [searchParams] = useSearchParams();

  const transId = searchParams.get("transId");
  const taskType = searchParams.get("taskType");

  React.useEffect(() => {
    if (taskType === "document_validation") {
      const getClaimNo = async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/${transId}`
            // {
            //   headers: {
            //                  'Authorization': `Bearer ${globalData?.accessToken}`
            //                }
            //  }
          )
          .then((res: any) => {
            setClaimNo(res?.data?.data?.claimNo);
          })
          .catch((err: any) => {});
      };
      getClaimNo();
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const handleLoginClick = () => {
    // Pass the current location as state
    navigate("/login", { state: { from: location } });
  };

  return (
    <React.Fragment>
      <Container maxWidth={"lg"} sx={{ my: 12 }}>
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent>
            {/* <Typography variant="h4" className="tp-text-header-primary">
              Business Rules
            </Typography> */}
            <Stack direction={"row"} alignItems={"center"}>
              <Grid container spacing={2}>
                <Grid item md={10}>
                  <Typography variant="h5" className="tp-text-header-primary">
                    <span className="tp-text-default">
                      Transaction No.: &ensp;
                    </span>
                    {taskType === "document_validation" ? "ACAP" : "ISIP"}-
                    {transId}
                  </Typography>
                  {taskType === "document_validation" ? (
                    <Typography variant="h5" className="tp-text-header-primary">
                      <span className="tp-text-default">Claim No.: &ensp;</span>
                      {claimNo}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              {!globalData.token && <Button size="small" onClick={handleLoginClick} className="tp-custom-button-primary">Override</Button>}
            </Stack>

            <br />
            <BusinessRulesTask
              transId={Number(transId)}
              taskType={taskType}
              //   expanded={true}
            />
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
}
