import { useState } from 'react';
import {
    TextField,
    Button,
    Container,
    Box,
    InputLabel
  } from "@mui/material";
  import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import bpims from "../assets/bpims_logo.png";
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useGlobalData } from "../contexts/GlobalDataContext";
import { useNavigate } from 'react-router-dom';
// import { UpdateUserApi } from '../api/UpdateUserApi';
// import { getUser } from '../utils/GetCurrentUser';
import dayjs from 'dayjs';

export default function ValidateMfa() {
    const defaultTheme = createTheme();
    const { globalData, updateGlobalData } = useGlobalData();
    const navigate = useNavigate();
    const [mfa, setMfa] = useState('');
    const username = JSON.parse(secureLocalStorage.getItem('username') as string);
    const session = JSON.parse(secureLocalStorage.getItem('session') as string);
    const challengeName = JSON.parse(secureLocalStorage.getItem('challengeName') as string);
    const [mainState, setMainState] = useState({
        apiError: "",
        isLoading: false,
      })

    const handleSubmit = async () => {
        const dateTimeString = dayjs().format('YYYY-MM-DD HH:mm:ss');
        // Clear previous errors
        setMainState((prevState) => ({
            ...prevState,
            apiError: "",
            isLoading: true,
        }));  
  
        await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/authentication/verify/mfa`,
            {
              username: username,
              session: session,
              mfaCode: mfa,
              challengeName: challengeName
            }
        ).then(async (res) => {
            if (res?.status === 200) {
                const { idToken, accessToken, refreshToken } = res.data?.data;
                const arrayToken = idToken?.split('.');
                const userData = JSON.parse(atob(arrayToken[1]));
                const role = userData['custom:role_name'];
                const username = userData['cognito:username'];
                const lastLogout = userData['custom:last_logout'];
                const status = userData['custom:status'];
                const tokenExpiration = userData['exp'];
                // const userToUpdate = await getUser(username, globalData);


                console.log("mfa-login",{ 
                    id: res?.data?.id,
                    firstName: res?.data?.givenName,
                    lastName: res?.data?.familyName,
                    role: role, 
                    username: username, 
                    idToken: idToken, 
                    isUserLogOut: false,
                    accessToken: accessToken,
                    lastLogin: dateTimeString,
                    lastLogout: lastLogout,
                    status: status,
                    tokenExpiration: tokenExpiration
                  })

                updateGlobalData({
                    id: res?.data?.id,
                    firstName: res?.data?.givenName,
                    lastName: res?.data?.familyName,
                    role: role, 
                    username: username, 
                    idToken: idToken, 
                    isUserLogOut: false,
                    accessToken: accessToken,
                    lastLogin: dateTimeString,
                    lastLogout: lastLogout,
                    status: status,
                    tokenExpiration: tokenExpiration
                });
                
                secureLocalStorage.setItem('id', JSON.stringify(res?.data?.id));
                secureLocalStorage.setItem('firstName', JSON.stringify(res?.data?.givenName));
                secureLocalStorage.setItem('lastName', JSON.stringify(res?.data?.familyName));
                secureLocalStorage.setItem('accessToken', JSON.stringify(accessToken));
                secureLocalStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                secureLocalStorage.setItem('idToken', JSON.stringify(idToken));
                secureLocalStorage.setItem('username', JSON.stringify(username));
                secureLocalStorage.setItem('role', JSON.stringify((role)));
                secureLocalStorage.setItem('lastLogin', JSON.stringify((dateTimeString)));
                secureLocalStorage.setItem('status', JSON.stringify((status)));
                secureLocalStorage.setItem('lastLogout', JSON.stringify((lastLogout)));
                secureLocalStorage.setItem('tokenExpiration', JSON.stringify((tokenExpiration)));
                
                // await UpdateUserApi(userToUpdate, globalData, username, accessToken);
            
                navigate('/', { replace: true });
            }
        }).catch((error: any) => {
            setMainState((prevState) => ({
                ...prevState,
                apiError: error?.response?.data || error?.message,
                isLoading: false,
              }));
        }).finally(() => {
            setMainState((prevState) => ({ ...prevState, isLoading: false }));
        });
    }
    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        height: "100vh"
                    }}
                    
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Box>
                            <img src={bpims} width={180} height={60} alt="img" />
                        </Box>
                        {mainState.apiError && (
                            <Alert severity="error" sx={{ width: "100%" }}>
                                {mainState.apiError}
                            </Alert>
                        )}
                        <div style={{ marginTop: "30px" }}>
                            <InputLabel>Please enter the code from your passcode app.</InputLabel>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                onChange={(e) => setMfa(e?.target?.value)}
                                value={mfa}
                                name="username"
                                autoComplete="username"
                                autoFocus
                                size='small'
                            />
                            <Button onClick={handleSubmit}  disabled={!mfa.trim()} sx={{ width: "100%", marginTop:"20px" }} size='small' variant='contained' color='error'>
                                {mainState?.isLoading === true ? (
                                    <>
                                        <CircularProgress color="inherit" size="1.5em" />
                                        &emsp;
                                    </>
                                    ) : (
                                        <></>
                                )}
                                Sign in
                            </Button>
                            <Button 
                                onClick={() => navigate('/')} 
                                sx={{
                                    width: "100%", 
                                    marginTop: "20px", 
                                    backgroundColor: "transparent", 
                                    color: "black", 
                                    '&:hover': {
                                        backgroundColor: "rgba(0, 0, 0, 0.1)"
                                    }
                                }} 
                                size='small' 
                                variant='contained'
                            >
                                Back
                            </Button>
                        </div>
                    </Box>
                </Container>
            </ThemeProvider>
      </>
    )
}