import {
  Typography,
  Box,
  CircularProgress,
  Chip,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import GenericToolbarSelect from "../user_management/table_components/GenericToolbarSelect";
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export default function LaborRateManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: ["Claims Admin"].includes(globalData?.role) ? "single" : "none",
    responsive: "standard",
    // resizableColumns: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <GenericToolbarSelect selectedRows={selectedRows} data={rows[selectedRows.data[0].dataIndex].id} endpoint="edit-labor"/>
    ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const statusMap: { [key: string]: string } = {
    null: "Not Available",
    "0": "Not Available",
    "1": "Available",
  };

  const columns = [
    // {
    //   name: "id",
    //   label: "ID",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         textAlign: "center",
    //         fontWeight: "bold",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: { whiteSpace: "nowrap", textAlign: "center" },
    //     }),
    //   },
    // },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        })
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // padding: "5px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins"
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              // textAlign: "center",
              fontFamily: "Poppins"
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      <Typography variant="h3" className="tp-text-header-primary">
        Labor Rates Management
      </Typography>
      <Typography
        sx={{
          color: "#607274",
        }}
      >
        Manage labor rates
      </Typography>
      <hr />
      <br />
      <br />
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={["Claims Admin"].includes(globalData?.role) &&
                <Button
                  className="tp-custom-button-primary "
                  size="small"
                  variant="contained"
                  startIcon={<GroupAddIcon />}
                  onClick={() => navigate("add-labor")}
                >
                  Add Labor Rate
                </Button>
              }
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
