import {
  Container,
  Button,
  Chip,
  CardContent,
  Card,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
  Box,
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { formatDate, isValidDateTime } from "../api/FormatDate";
import axios from "axios";
import UserManagement from "../components/user_management/UserManagement";
import { toTitleCase } from "../api/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCurrentUser } from "../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";
import AcapTaskManagement from "../components/user_management/AcapTaskManagement";

export function ViewButton({ url }: any) {
  const navigate = useNavigate();

  return (
    <Button
      color="warning"
      variant="contained"
      size="small"
      onClick={() => navigate(url)}
    >
      Edit
    </Button>
  );
}

export default function AcapTaskManagementPage() {
  const { globalData, updateGlobalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  const [processors, setProcessors] = React.useState<any>([]);
  //   const [columns, setColumns] = React.useState<any>([]);
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });

  const navigate = useNavigate();

  const convertStatus = (status: string) => {
    if (status === "1") {
      return {
        label: "Active",
        component: (
          <Chip
            label="Active"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === "0" || status == null || status === "") {
      return {
        label: "Not Active",
        component: (
          <Chip
            label="Not Active"
            size="small"
            variant="outlined"
            color="warning"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  // #region getUsers
  const getUsers = async () => {
    const loggedInUser = await getCurrentUser(globalData);
    const cognitoLoginUrl =
      process.env.COGNITO_LOGIN_PAGE ||
      "https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions";
    if (loggedInUser?.status === 0) {
      updateGlobalData({
        role: "",
        username: null,
        idToken: null,
        isUserLogOut: true,
        lastLogin: null,
        lastLogout: null,
      });
      secureLocalStorage.removeItem("username");
      secureLocalStorage.removeItem("role");
      secureLocalStorage.removeItem("idToken");
      secureLocalStorage.removeItem("lastLogin");
      secureLocalStorage.removeItem("lastLogout");
      secureLocalStorage.removeItem("status");
      alert("Deactivated user cannot get users info.");
      window.location.href = cognitoLoginUrl;
      return;
    } else {
      setMainState({ ...mainState, isLoading: true });
      try {
        (async () => {
          axios({
            method: "get",
            url: `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/open-tasks`,
            headers: {
              Authorization: `Bearer ${globalData?.accessToken}`,
            },
          })
            .then((res: any) => {
              const data = res.data;
              // console.log("data", data)
              
              const flatData = data.map((item: any) => ({
                id: item.id,
                assignedTo: item.userDetails?.id,
                channel: item.channel,
                sender: item.sender,
                taskType: item.taskType,
                saveDate: formatDate(item.saveDate),
                // status: item.status,
                priority: item.priority
              }));

              // console.log("flatData", flatData)
              setRows(flatData);

              setMainState({ ...mainState, isLoading: false });
            })
            .catch((err: any) => {
              console.error(err)
              let errorMessage = "";
              if (err?.response?.status === 401)
                errorMessage = err?.response?.data;
              if (err?.response?.status === 400)
                errorMessage = err?.response?.data;
              setMainState({ ...mainState, isLoading: false });
            });
        })();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const getAllProcessors = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap-task/processors`, {
      headers: {
        Authorization: `Bearer ${globalData?.accessToken}`,
      },
    }).then((res: any) => {
      const tfProcessors = res.data.map((item: any) => ({
        id: item.userId,
        username: item.username
      }))
      setProcessors(tfProcessors)
    }).catch((err: any) => console.error(err))
  }

  React.useEffect(() => {
    getAllProcessors();
    getUsers();
  }, []);

  return (
    <Container sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 650,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <CardContent>
            <AcapTaskManagement
              globalData={globalData}
              mainState={mainState}
              rows={rows}
              processors={processors}
              setProcessors={setProcessors}
              setRows={setRows}
            />
          </CardContent>
        </CardContent>
        <br />
        <br />
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)}{" "}
            {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Card>
    </Container>
  );
}
