import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";
// import {GlobalDataContext } from "../contexts/GlobalDataContext";

export type AdminLogData = {
  id?: number | null;
  event: string | null;
  user: string | null;
  description: string | null;
  ipAddress?: string | null;
  saveDate?: string | null;
  fullName?: string | null;
};

type AdminLogDataRequest = {
  event: string | null;
  user: string | null;
  description: string | null;
  ipAddress?: string | null;
  saveDate?: string | null;
  fullName?: string | null;
};

interface AdminLogState {
  data: AdminLogData[];
  isLoading: boolean;
  pagination: {
    page: number;
    count: number;
    size: number;
  };
}

interface AdminLogDataContextInterface {
  adminLogData: AdminLogState;
  setAdminLogData: React.Dispatch<React.SetStateAction<AdminLogState>>;
  updateAdminLogData: (data: Partial<AdminLogData>[]) => void;
  fetchAdminLogData: (
    accessToken: string,
    key: string,
    page: number,
    size: number
  ) => Promise<void>;
  createAdminLogData: (accessToken:string,dataRequest: AdminLogDataRequest) => Promise<void>;
}

const initialState: AdminLogState = {
  data: [],
  isLoading: false,
  pagination: {
    page: 1,
    count: 0,
    size: 3,
  },
};

const initialContextState: AdminLogDataContextInterface = {
  adminLogData: initialState,
  setAdminLogData: () => {},
  updateAdminLogData: () => {},
  fetchAdminLogData: async () => {},
  createAdminLogData: async () => {},
};

export const AdminLogDataContext =
  createContext<AdminLogDataContextInterface>(initialContextState);
export const useAdminLogData = () => useContext(AdminLogDataContext);

type AdminLogDataProviderProps = {
  children: ReactNode;
};

export default function AdminLogDataStore({
  children,
}: AdminLogDataProviderProps) {
  const [adminLogData, setAdminLogData] =
    useState<AdminLogState>(initialState);

  // Function to update parts of the global data
  const updateAdminLogData = useCallback((data: Partial<AdminLogData>[]) => {
    setAdminLogData((prev) => ({
      ...prev,
      data: prev.data.map((item, index) =>
        data[index] ? { ...item, ...data[index] } : item
      ),
    }));
  }, []);

  const fetchAdminLogData = useCallback(
    async (accessToken:string, key: string, page: number, size: number) => {
      try {
        setAdminLogData((prev) => ({
          ...prev,
          isLoading: true,
        }));

        const paginatedResponse = await axios.get(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/api/admin-logs/pagination/search?page=${page - 1}&size=${size}`,
          {
            headers: {
                           'Authorization': `Bearer ${accessToken}`
                         }
           }
        );
        const paginatedData = paginatedResponse?.data?.data?.content;

        const totalItems = paginatedResponse?.data?.data?.totalPages;
        const totalPages = Math.ceil(totalItems / size);

        setAdminLogData((prev) => ({
          ...prev,
          data: paginatedData,
          isLoading: false,
          pagination: {
            ...prev.pagination,
            page: page,
            count: totalPages,
            size: size,
          },
        }));
      } catch (err) {
        console.error(err);
        setAdminLogData((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    },
    []
  );

  const createAdminLogData = useCallback(async (accessToken:string, dataRequest: AdminLogDataRequest) => {
    setAdminLogData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin-logs/`,
        dataRequest,
        {
          headers: {
                         'Authorization': `Bearer ${accessToken}`
                       }
         }
      );

      await fetchAdminLogData(accessToken, "", 1, 3);
    } catch (err) {
      console.error(err);
    } finally {
      setAdminLogData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  }, [fetchAdminLogData]);

  return (
    <AdminLogDataContext.Provider
      value={{
        adminLogData,
        setAdminLogData,
        updateAdminLogData,
        fetchAdminLogData,
        createAdminLogData,
      }}
    >
      {children}
    </AdminLogDataContext.Provider>
  );
}
