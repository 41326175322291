import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/logo.json";
import LogoIsip from "../../assets/logo_isip.json";
// import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import axios from "axios"; // Import axios
import bpimslogo from "../../assets/bpims_logo.png";

const defaultTheme = createTheme();

export default function ForgotPassword() {
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
    showDialog: false, // Add showDialog state
  });
  const [formError, setFormError] = React.useState<any>("");
  const navigate = useNavigate();
  // const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const validateForm = (data: FormData) => {
    let isValid = true;
    const errors: any = {};
    // Validate username
    if (data.get("username")?.toString().trim() === "") {
      errors.username = "Username should not be empty.";
      isValid = false;
    }
    setFormError(errors);
    return isValid;
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMainState({ ...mainState, isLoading: true });
    const data = new FormData(event.currentTarget);
    if (validateForm(data)) {
      // Form is valid, perform the API request to your backend
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`,
          {
            username: data.get("username"),
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        setMainState({
          ...mainState,
          isLoading: false,
          error: "",
          showDialog: true,
        }); // Show dialog
      } catch (error) {
        console.error("Failed to request password reset", error);
        setMainState({
          ...mainState,
          isLoading: false,
          error: "Failed to send reset link. Please try again.",
        });
      }
    } else {
      // Form is invalid, display error messages
      console.log("Form validation failed");
      setMainState({ ...mainState, isLoading: false });
    }
  };
  const handleDialogClose = () => {
    setMainState({ ...mainState, showDialog: false });
    navigate("/"); // Redirect to login page
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box>
              <img src={bpimslogo} width={180} height={60} alt="img" />
            </Box>
          {mainState.error !== "" ? (
            <Alert
              severity="error"
              sx={{
                width: "100%",
              }}
            >
              {mainState.error}
            </Alert>
          ) : (
            <></>
          )}
          <Box>
            <Typography variant="h4">Forgot Password</Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Type your Username"
                type="text"
                name="username"
                autoFocus
                error={!!formError.username}
              />
              {!!formError?.username && (
                <FormHelperText error id="username">
                  {formError?.username}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#a42a25",
                ":hover": {
                  backgroundColor: "#8b1f1a", // Change this to your desired hover color
                },
              }}
              disabled={mainState.isLoading}
            >
              {mainState.isLoading ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Send Reset Link"
              )}
            </Button>
            <Link to="/" style={{ textDecoration: "none", marginTop: "10px" }}>
              <Button fullWidth>Back to Login</Button>
            </Link>
          </Box>
        </Box>
      </Container>
      <Dialog open={mainState.showDialog} onClose={handleDialogClose}>
        <DialogTitle>Password Reset Link Sent</DialogTitle>
        <DialogContent>
          <Typography>
            If an account with that username exists, we have sent a password
            reset link to it.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
