import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
  ButtonGroup,
  Alert,
  Stack,
  Zoom,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { channel } from "diagnostics_channel";
import useAlertNotification from "../../hooks/useAlertNotification";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import BusinessRules from "./checklist/BusinessRules";
import { NotificationContext } from "../../contexts/NotificationContext";
import { capitalizeAndReplaceUnderscore, isValidDate, isValidNumber } from "../../utils/stringUtils";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";
import { z, ZodError } from "zod";

// Validation
export const driverLicenseOrSchema = z.object({
  date: z.string()
    .min(1, "Date is required")
    .nullable()
    .refine(value => value !== null, "Date should not be empty")
    .refine(isValidDate, "Invalid date format"),
  name: z.string().min(1, "Name is required").nullable().refine(value => value !== null, "Name should not be empty"),
  licenseNumber: z.string().min(1, "License Number is required").nullable().refine(value => value !== null, "License Number should be empty")
});
export type DriverLicenseOrForm = z.infer<typeof driverLicenseOrSchema>;

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
export default function DriverLicenseORForm({
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
  modalRef
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  // custom hook
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { validateBusinessRules } = useBusinessRules();
  const { notification, setNotification } =
    React.useContext(NotificationContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license-or?transId=${transID}&id=${docId}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;
            const statusOptions: any = {
              0: null,
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData(
              globalData!.accessToken!,
              {
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View Driver License official receipt form with document id #${docId}`,
              user: globalData.username,
              fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
              roleLevel: globalData.role
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // TODO update the endpoint and API
  const updateData = async () => {
    try {

      driverLicenseOrSchema.parse(mainState.data);

      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license-or?transId=${transID}&id=${docId}`,
        mainState.data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${globalData?.accessToken}`
          },
        }
      );
      const data = res.data.data;

      validateBusinessRules(globalData!.accessToken!,
        `/api/acap/document-validation/driver-license-or/business-rules?transId=${transID}&id=${docId}&taskType=${"document_validation"}`,
        mainState?.data,
        transID,
        "document_validation"
      );

      createAuditTrailData(
        globalData!.accessToken!,
        {
        transId: transID,
        taskType: "document_validation",
        event: "update_form",
        description: `Update Driver License Official Receipt form with document id #${docId}`,
        user: globalData.username,
        fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
        roleLevel: globalData.role
      });

      setMainState((prev: any) => ({ ...prev, formError: {} }));

      setNotification({
        level: "success",
        showAlert: true,
        message: data.message,
      });
    } catch (error: any) {
      if (error.errors) {
        error.errors.forEach((err: any) => {
          alert(`${err.path.join(".")}: ${err.message}`);
        });
      } else {
        alert(error?.response?.data || "An unknown error occurred.");
      }
    }
  };

  const isAnyFieldEmpty = () => {

    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {

        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {

        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    //apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  // const handleBlur = (id: any) => {
  //   if (changed[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData(transID, 'LtoOr', false)
  //   }

  // }

  //#endregion Control Methods

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <Tooltip
                title="Ex. 12/21/2012"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Stack direction={"row"}>
                    <Typography variant="body1" className="tp-text-bold">
                      Date <span className="tp-text-required">*</span>
                    </Typography>
                  </Stack>
                  <OutlinedInput
                    id="orDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Date Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={data.orDateConf + "%"} size="small" className={data.orDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.orDate || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('or_date')}
                    inputProps={{
                      "aria-label": "orDate",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.orDate === "" ||
                      mainState?.formError?.orDate ||
                      !isValidDate(mainState?.data?.orDate)
                    }
                  />
                  {(mainState?.data?.orDate === "" ||
                    mainState?.formError?.orDate ||
                    !isValidDate(mainState?.data?.orDate)) && (
                      <FormHelperText error id="orDate">
                        {!isValidDate(mainState?.data?.orDate) && "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Name
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="name"
                  size="small"
                  type="text"
                  placeholder={`Enter Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.name || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "name",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.name === ""}
                />
                {mainState?.data?.name === "" && (
                  <FormHelperText error id="name">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  License Number
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="licenseNumber"
                  size="small"
                  type="text"
                  placeholder={`Enter License Number Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.licenseNumber || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "licenseNumber",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.licenseNumber === ""}
                />
                {mainState?.data?.licenseNumber === "" && (
                  <FormHelperText error id="licenseNumber">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <br />
          {/* 
            // #region BusinessRules
          */}
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"document_validation"} documentType={"driver_license_or"} />
          <br />
          {/* 
            // #region ForProcessor
          */}
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                {/* {mainState?.formError?.status} */}
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/driver-license-or"}
                    documentType={"Driver License OR"}
                    close={close}
                    modalRef={modalRef}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                    color="error"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec", "Call Center"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
